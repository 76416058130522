import React from 'react';
import './Locations.scss';
import { Grid, Container, Typography, Paper, FormControl, InputLabel, Select, Button, Divider } from '@material-ui/core';
import LocationData from './LocationData';
import PhoneIcon from '@material-ui/icons/Phone';
import DialpadIcon from '@material-ui/icons/Dialpad';

export default function Locations() {
    const [location, setLocation] = React.useState('');
  
    const handleChange = (event) => {
        setLocation(event.target.value);
    };
  
    return (
        <div className="locationcareerRoot">
            <Grid item xs={12} className="locationcareerHero">
                <Typography variant="h2" className="heroSub">
                    Locations
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <Grid item xs={12} className="locationSelectorWrapper">
                    <Paper className="locationSelectorPaper">
                        <Typography variant="h4" className="needsBolding">
                            Find a store near you!
                        </Typography>
                        <Typography variant="h5" className="subtitle">
                            We operate throughout California extending from the central valley to the central coast.
                        </Typography>
                        <form className="formLocation">
                            <FormControl variant="outlined" className="locationSelector">
                                <InputLabel className="inputLocation">Locations</InputLabel>
                                <Select
                                    native
                                    value={location}
                                    onChange={handleChange}
                                    label="Locations"
                                    className="locationSelect"
                                >
                                    <option className="background" aria-label="None" value="" />
                                    <option className="background" value={0}>Fresno, CA Corporate Office</option>
                                    <option className="background" value={1}>Fresno, CA Store</option>
                                    <option className="background" value={2}>Visalia, CA Store</option>
                                    <option className="background" value={3}>Porterville, CA Store</option>
                                    <option className="background" value={4}>Clovis, CA Store</option>
                                    <option className="background" value={5}>Madera, CA Store</option>
                                    <option className="background" value={6}>Citrus Heights, CA Store</option>
                                    <option className="background" value={7}>Bakersfield, CA Store</option>
                                    <option className="background" value={8}>Sacramento, CA Store</option>
                                    <option className="background" value={9}>West Sacramento, CA Store</option>
                                    <option className="background" value={10}>Modesto, CA Store</option>
                                    <option className="background" value={11}>Stockton, CA Store</option>
                                    <option className="background" value={12}>Turlock, CA Store</option>
                                    <option className="background" value={13}>Salinas, CA Store</option>
                                    <option className="background" value={14}>Fowler, CA Gas Plant</option>
                                </Select>
                            </FormControl>
                            <Button variant="outlined" type="button" href={'#' + location} className="locationButton">Take me there</Button>
                        </form>
                    </Paper>
                </Grid>
                <div className="locationslistRoot">
                    <Grid container spacing={1}>
                        {LocationData.map((LD, index) => (   
                            <Grid item md={6} xs={12} key={index} id={index}>
                                <Paper className="locationPaper">
                                    <img src={LD.img} alt={LD.location} className="llImg"></img>
                                    <div className="locationPadding">
                                        <Typography variant="h4" className="llLocation bolding">{LD.location}</Typography>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="h5">{LD.address}</Typography>
                                                <Typography>{LD.zip}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className="llSub spacing"><PhoneIcon className="llIcon" />Main: {LD.phone}</Typography>
                                                <Typography className="llSub"><DialpadIcon className="llIcon" />Fax: {LD.fax}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider className="dividerSpacing" />
                                        <Grid container>
                                            <Grid item md={6} xs={12}>
                                                <Typography variant="h5" className="bolding">Store Hours</Typography>
                                                <Typography>Mon - Fri: {LD.hoursMF}</Typography>
                                                <Typography>Sat: {LD.hoursSAT}</Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12}>
                                                <iframe src={LD.google} title={LD.location} allowFullScreen loading="lazy" className="llGoogle"></iframe>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Container>
        </div>
    )
}