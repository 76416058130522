import './Home.scss';
import React from 'react';
import SignUp from './SignUp/SignUp';
import FeaturedManufacturers from './FeaturedManufacturers/FeaturedManufacturers';
import { Link } from 'react-router-dom';
import { Container, Grid, Box, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Typography, Paper, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import logo from '../../imgs/bws-logo2.svg';
import studentDiscountsIcon from '../../imgs/studentDiscounts.svg';
import buyersGuideIcon from '../../imgs/buyersGuide.svg';
import cylinderManagment from '../../imgs/cylinderManagment.svg';
import equipment from '../../imgs/services/equipment-rental-repair.svg';
import gases from '../../imgs/services/gases2.svg';
import supplies from '../../imgs/services/supplies.svg';
import rental from '../../imgs/services/rental.svg';
import safety from '../../imgs/products/safety.svg';
import shopEquipment from '../../imgs/products/shop-equipment.svg';
import helmet from '../../imgs/products/helmet.svg';
import grindingCutting from '../../imgs/products/grinding-cutting.svg';
import powerTools from '../../imgs/products/powerTools.svg';
import welding from '../../imgs/products/welding.svg';

export default function Home() {
    function handleRotate(e) {
        var parent = e.currentTarget.childNodes[0];

        var child = parent.childNodes[2];

        child.classList.toggle("rotate")
    }

    var today = new Date();
    const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    var currentMonth = monthNames[today.getMonth()];

    return (
        <div className="homeRoot">
            <Grid item xs={12} className="header">
                {/* <img src={logo} alt="bws-logo"/> */}
            </Grid>
            <Container maxWidth="lg">
                <Grid container className="home-content">
                    <Grid container spacing={1} className="specials">
                        <Grid item md={6} xs={12} className="guideWrapper">
                            <Paper square className="guideContent">
                                <div className="guideImgWrapper">
                                    <Link to={process.env.PUBLIC_URL + '/student_essentials.pdf'} target="_blank">
                                        <img src={studentDiscountsIcon} alt="Student Discounts Icon" className="guideImg" />
                                    </Link>
                                </div>
                                <Typography variant="h4">Student Welder? Need school supplies?</Typography>
                                <Typography variant="h5" className="subtitle2" component="h3">Check out our Student Discounts!</Typography>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12} className="guideWrapper">
                            <Paper square className="guideContent">
                                <div className="guideImgWrapper">
                                    <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf'} target="_blank">
                                        <img src={buyersGuideIcon} alt="Buyers Guide Icon" className="guideImg" />
                                    </Link>
                                </div>
                                <Typography variant="h4">Interested in the products we carry?</Typography>
                                <Typography variant="h5" className="subtitle2" component="h3">Check out our Buyers Guide!</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                    <SignUp />
                    <Grid container className="servicesContent">
                        <Box clone order={{ xs: 3, md: 2}}>
                            <Grid item md={6} xs={12} className="servicesText">
                                <Typography variant="h4">Welding & Industrial Supplies</Typography>
                                <Typography variant="h5">We are a full line distributor of welding and industrial supplies and are factory authorized to service and sell parts from popular brands such as Miller, Lincoln Electric, ESAB, and more!</Typography>
                                <Button className="homeButton" variant="contained" color="primary" component={Link} to="/welding-industrial-supplies">
                                    Learn More
                                </Button>
                            </Grid>
                        </Box>
                        <Box clone order={{ xs: 2, md: 3}}>
                        <Grid item md={6} xs={12}>
                            <img src={supplies} alt="welding/industrial supplies" />
                        </Grid>
                        </Box>
                    </Grid>
                    <Grid container className="servicesContent">
                            <Grid item md={6} xs={12}>
                                <img src={gases} alt="gases" />
                            </Grid>
                            <Grid item md={6} xs={12} className="servicesText">
                                <Typography variant="h4">Gas Services</Typography>
                                <Typography variant="h5">We are a full-service supplier of industrial and welding gases, high purity specialty gases, bulk CO2 and nitrogen generation systems, dry ice and operate a fleet of bulk tanker trucks! </Typography>
                                <Button className="homeButton" variant="contained" color="primary" component={Link} to="/gas-services">
                                    Learn More
                                </Button>
                            </Grid>
                        
                    </Grid>
                    <Grid container className="servicesContent">
                        <Box clone order={{ xs: 2, md: 3}}>
                            <Grid item md={6} xs={12}>
                                <img src={cylinderManagment} alt="Cylinder Management" />
                            </Grid>
                        </Box>
                        <Box clone order={{ xs: 3, md: 2}}>
                            <Grid item md={6} xs={12} className="servicesText">
                                <Typography variant="h4">Cylinder Management (ACM)</Typography>
                                <Typography variant="h5">Keeping track of your cylinders can be hardwork. We make it easy with Advanced Cylinder Management (ACM) to accurately track all of your cylinder deliveries and returns.</Typography>
                                <Button className="homeButton" variant="contained" color="primary" component={Link} to="/cylinder-management">
                                    Learn More
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container className="servicesContent">
                            <Grid item md={6} xs={12}>
                                <img src={equipment} alt="equipment repair" />
                            </Grid>
                            <Grid item md={6} xs={12} className="servicesText">
                                <Typography variant="h4">Service Department & Repairs</Typography>
                                <Typography variant="h5">Is your welding equipment in need of service or repairs? We have factory trained and certified technicians here to help.</Typography>
                                <Button className="homeButton" variant="contained" color="primary" component={Link} to="/service-department-repairs">
                                    Learn More
                                </Button>
                            </Grid>
                    </Grid>
                    <Grid container className="servicesContent dryiceWrapper">
                        <Box clone order={{ xs: 2, md: 3}}>
                            <Grid item md={6} xs={12}>
                                <img src={rental} alt="dry ice" />
                            </Grid>
                        </Box>
                        <Box clone order={{ xs: 3, md: 2}}>
                            <Grid item md={6} xs={12} className="servicesText">
                                <Typography variant="h4">Rental Equipment</Typography>
                                <Typography variant="h5">Are you looking to rent equipment for a day, week or even a month? We have you covered and can even deliver the equipment to you!</Typography>
                                <Button className="homeButton" variant="contained" color="primary" component={Link} to="/rental-equipment">
                                    Learn More
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container className="productsHeader">
                        <Grid item xs={12}>
                            <Typography variant="h4" className="productCategories">
                                Product Categories
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={welding} alt="welding" />
                                        <Typography variant="h5">Welding</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#welding'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="MIG Welders, Wire, Consumables & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="TIG Welders, Torches, Rods, Consumables & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Stick Welders, Consumables & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Multi-process Welders, Consumables & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Engine Drives, Welding Cables"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={powerTools} alt="power tools/hand tools" />
                                        <Typography variant="h5">Power Tools & Hand Tools</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#tools'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Power Saws, Drills & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Vises, Clamps & Pliers"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Wrenches, Files, Knives & Hammers"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Tape Measures, Magnetic Tools, Levels & Templates"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={grindingCutting} alt="grinding/cutting" />
                                        <Typography variant="h5">Grinding & Cutting</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#grinding'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Plasma Cutting Machines, Torches, Consumables & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Torch Cutting Kits, Cutting Tips, Gas Hoses, Regulators & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Grinders, Cut-Off Wheels, Flap Discs, Grinding Wheels & Wire Wheels"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={helmet} alt="welding helmets" />
                                        <Typography variant="h5">Welding Helmets</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#helmets'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Auto Darkening"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Fixed Shade Shields"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Welding Helmet Accessories"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={safety} alt="safety/comfort products" />
                                        <Typography variant="h5">Safety & Comfort Products</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#safety'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Hearing Protection"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Respiratory Protection"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Face Shields & Goggles"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Jackets, Bibs, Aprons, Gloves & Sleeves"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Accordion>
                                    <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={handleRotate}
                                    >
                                        <img src={shopEquipment} alt="industrial shop equipment" />
                                        <Typography variant="h5">Industrial Shop Equipment</Typography>
                                        <ExpandMoreIcon />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <List>
                                            <Link to={process.env.PUBLIC_URL + '/buyers_guide.pdf#industrial'} target="_blank"  className="noLinks">
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Air Compressors, Air Tools & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Industrial Drill Press, Magnetic Drills & Drill Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Belt Grinders, Bench Grinders & Accessories"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText 
                                                        primary="Shop Ventilation"
                                                    />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mfgHeader">
                        <FeaturedManufacturers />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}