import React from 'react';
import './WeldingIndustrialSupplies.scss';
import { Grid, Container, Typography } from '@material-ui/core';

export default function WeldingIndustrialSupplies() {
    return (
        <div className="wisRoot">
            <Grid item xs={12} className="wisHero">
                <Typography variant="h2" className="subtitle2">
                    Welding & Industrial Supplies
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <Grid container spacing={2} className="containerWis">
                    <Grid item xs={12} md={6} className="wisDesc">
                        <Typography variant="h4" className="heading">Our Experience</Typography>
                        <Typography className="wisContent">
                            Barnes Welding Supply has over 70 years of experience in the industry, we are locally owned and operated, and we pride ourselves on unmatched customer service. Our highly trained staff has the knowledge and experience to help you find the best solution for your project. We sell and service a full line of welding supplies and equipment from the industry’s leading manufacturers and we carry the industry’s top products for all your welding supply needs. Stop by any of our 13 locations and speak with our staff to see how we can help you. 
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <iframe 
                        width="100%" 
                        height="350px" 
                        src="https://www.youtube.com/embed/evzBE0qiSsA" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen 
                    />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}