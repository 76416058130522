import FresnoOffice from '../../imgs/locations/fresnoOffice/fresno-office.jpg';
import Visalia from '../../imgs/locations/visaliaStore/visalia-store.jpg';
import Porterville from '../../imgs/locations/portervilleStore/porterville-store.jpg';
import Clovis from '../../imgs/locations/clovisStore/clovis-store.jpg';
import Madera from '../../imgs/locations/maderaStore/madera-store.jpg';
import CitrusHeights from '../../imgs/locations/citrusHeightsStore/citrusheights-store.jpg';
import Bakersfield from '../../imgs/locations/bakersfieldStore/bakersfield-store.jpg';
import Sacramento from '../../imgs/locations/sacramentoStore/sacramento-store.jpg';
import Modesto from '../../imgs/locations/modestoStore/modesto-store.jpg';
import Stockton from '../../imgs/locations/stocktonStore/stockton-store.jpg';
import Turlock from '../../imgs/locations/turlockStore/turlock-store.jpg';
import Salinas from '../../imgs/locations/salinasStore/salinas-store.jpg';
import Fowler from '../../imgs/locations/fowlerPlant/fowler-plant.jpg';
import Fresno from '../../imgs/locations/fresnoStore/fresno-store.jpg';
import WestSac from '../../imgs/locations/westSacramentoStore/west-sac-store.jpeg';

const LocationData = [
    {
        img: FresnoOffice,
        location: 'Fresno, CA Corporate Office',
        address: '2825 S. Elm Ave. #101', 
        zip: 'Fresno, CA 93706',
        phone: '(559) 233-6684',
        fax: '(559) 233-4206',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: 'Closed',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.989347277386!2d-119.79185768471298!3d36.69879387996958!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8094607543b299b1%3A0x6bb10e88028055e5!2sBarnes%20Welding%20Supply%20%2F%20Barnes%20Specialty%20Gases!5e0!3m2!1sen!2sus!4v1616525741827!5m2!1sen!2sus',
    },
    {
        img: Fresno,
        location: 'Fresno, CA Store',
        address: '2701 E Jensen Ave.', 
        zip: 'Fresno, CA 93706',
        phone: '(559) 233-9353',
        fax: '(559) 233-4581',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3198.6102590577657!2d-119.78161538465346!3d36.70790238058953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80945fdaedefa105%3A0xb7f710465545e0ef!2s2701%20E%20Jensen%20Ave%2C%20Fresno%2C%20CA%2093706!5e0!3m2!1sen!2sus!4v1646162318415!5m2!1sen!2sus',
    },
    {
        img: Visalia,
        location: 'Visalia, CA Store',
        address: '2239 E. Main St.',
        zip: 'Visalia, CA 93292',
        phone: '(559) 733-2335',
        fax: '(559) 733-9614',
        hoursMF: '7:30 am to 5:00 pm',
        hoursSAT: '8:00 am to 12:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d51421.18201682825!2d-119.21399353134157!3d36.340818165263485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80952f9a1adea915%3A0xe01ebc41f7ac24a9!2sBarnes%20Welding%20Supply%20-%20Visalia!5e0!3m2!1sen!2sus!4v1616521929771!5m2!1sen!2sus',
    },
    {
        img: Porterville,
        location: 'Porterville, CA Store',
        address: '1204 W. Olive Ave.',
        zip: 'Porterville, CA 93257',
        phone: '(559) 782-1025',
        fax: '(559) 782-3246',
        hoursMF: '7:30 am to 5:00 pm',
        hoursSAT: '8:00 am to 12:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12900.473285736889!2d-119.043947!3d36.066218000000006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80eab80ac57c1aa1%3A0x66d2a4df867f209a!2sBarnes%20Welding%20Supply%20-%20Porterville!5e0!3m2!1sen!2sus!4v1616521900227!5m2!1sen!2sus',
    },
    {
        img: Clovis,
        location: 'Clovis, CA Store',
        address: '2742 Clovis Ave.',
        zip: 'Clovis, CA 93612',
        phone: '(559) 292-1234',
        fax: '(559) 292-1261',
        hoursMF: '7:30 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12778.854339116931!2d-119.70066200000001!3d36.801417!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80945c74ece1f70b%3A0xe61058b04be76da4!2sBarnes%20Welding%20Supply%20-%20Clovis!5e0!3m2!1sen!2sus!4v1616521849777!5m2!1sen!2sus',
    },
    {
        img: Madera,
        location: 'Madera, CA Store',
        address: '311 S. Pine Ave.',
        zip: 'Madera, CA 93637',
        phone: '(559) 718-2072',
        fax: '(559) 718-2076',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 12:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3188.519942815006!2d-120.0756509490513!3d36.94963726687437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80940b79279c6f71%3A0xe8dfabad84632cca!2sBarnes%20Welding%20Supply%20-%20Madera!5e0!3m2!1sen!2sus!4v1616531178516!5m2!1sen!2sus',
    },
    {
        img: CitrusHeights,
        location: 'Citrus Heights, CA Store',
        address: '6005 Auburn Blvd. #100',
        zip: 'Citrus Heights, CA 95621',
        phone: '(916) 560-2006',
        fax: '(916) 560-2010',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3114.8125786297105!2d-121.32712904901088!3d38.67617516745847!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809adf1c250850b3%3A0xd46d0baf9bd04dd4!2sBarnes%20Welding%20Supply%20-%20Citrus%20Heights!5e0!3m2!1sen!2sus!4v1616521811006!5m2!1sen!2sus',
    },
    {
        img: Bakersfield,
        location: 'Bakersfield, CA Store',
        address: '5150 Rosedale Hwy.',
        zip: 'Bakersfield, CA 93308',
        phone: '(661) 322-9353',
        fax: '(661) 634-9001',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3252.8664669964182!2d-119.06050564908627!3d35.383767753738425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80ea426d70b10b6d%3A0x8792927bd15cfd5c!2sBarnes%20Welding%20Supply%20-%20Bakersfield!5e0!3m2!1sen!2sus!4v1616522255091!5m2!1sen!2sus',
    },
    {
        img: Sacramento,
        location: 'Sacramento, CA Store',
        address: '4218 Power Inn Rd.',
        zip: 'Sacramento, CA 95826',
        phone: '(916) 456-2478',
        fax: '(916) 737-7426',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3120.8650492036445!2d-121.41163224901425!3d38.536878875618754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809ad09155555555%3A0x8eda6f79cf9ed6ca!2sBarnes%20Welding%20Supply%20-%20Sacramento!5e0!3m2!1sen!2sus!4v1616522784225!5m2!1sen!2sus',
    },
    {
        img: WestSac,
        location: 'West Sacramento, CA Store',
        address: '501 Harbor Blvd # A',
        zip: 'West Sacramento, CA 95691',
        phone: '(916) 913-0902',
        fax: '(916) 913-0907',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3118.818224124311!2d-121.54671259999998!3d38.5840336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809ad51076236367%3A0x39879220ff80934a!2sBarnes%20Welding%20Supply%20-%20West%20Sacramento!5e0!3m2!1sen!2sus!4v1704477479195!5m2!1sen!2sus',
    },
    {
        img: Modesto,
        location: 'Modesto, CA Store',
        address: '1501 Coldwell Ave.',
        zip: 'Modesto, CA 95350',
        phone: '(209) 491-2780',
        fax: '(209) 491-2888',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 12:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3158.8119860812853!2d-121.01909474903506!3d37.65362402679411!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809053d8c8c62e47%3A0x88c822a6fb6502d2!2sBarnes%20Welding%20Supply%20-%20Modesto!5e0!3m2!1sen!2sus!4v1616523409288!5m2!1sen!2sus',
    },
    {
        img: Stockton,
        location: 'Stockton, CA Store',
        address: '1444 E. Mariposa Rd.',
        zip: 'Stockton, CA 95205',
        phone: '(209) 463-9353',
        fax: '(209) 463-9357',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.3878276360188!2d-121.25691124902829!3d37.9447295100364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80906d313d3cc20f%3A0x58a1b156c688d630!2sBarnes%20Welding%20Supply%20-%20Stockton!5e0!3m2!1sen!2sus!4v1616523985129!5m2!1sen!2sus',
    },
    {
        img: Turlock,
        location: 'Turlock, CA Store',
        address: '1105 Lander Ave.',
        zip: 'Turlock, CA 95380',
        phone: '(209) 216-5592',
        fax: '(209) 216-5593',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 12:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.079222223368!2d-120.85152994903895!3d37.48245683659723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8091078ef157bf69%3A0x2941742e1901c2d5!2sBarnes%20Welding%20Supply%20-%20Turlock!5e0!3m2!1sen!2sus!4v1616524431300!5m2!1sen!2sus',
    },
    {
        img: Salinas,
        location: 'Salinas, CA Store',
        address: '1040 Abbott St.',
        zip: 'Salinas, CA 93901',
        phone: '(831) 783-1806',
        fax: '(831) 783-1828',
        hoursMF: '7:00 am to 5:00 pm',
        hoursSAT: '8:00 am to 5:00 pm',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3200.75994487647!2d-121.63669854905787!3d36.656225283392075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808df87c04235e91%3A0xe2ff5cdad3cc0f33!2sBarnes%20Welding%20Supply%20-%20Salinas!5e0!3m2!1sen!2sus!4v1616524811983!5m2!1sen!2sus',
    },
    {
        img: Fowler,
        location: 'Fowler, CA Gas Plant',
        address: '7835 E. Manning Ave.',
        zip: 'Fowler, CA 93625',
        phone: '(559) 834-1613',
        fax: '(831) 834-4747',
        hoursMF: '8:00 am to 5:00 pm',
        hoursSAT: 'Closed',
        google: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3202.8929630734424!2d-119.65106894905902!3d36.604886886270954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8094f0fd76a61ed7%3A0xfeaabe762f4906d8!2sBarnes%20Welding%20Supply!5e0!3m2!1sen!2sus!4v1616531988512!5m2!1sen!2sus',
    },
]

export default LocationData;