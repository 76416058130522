import { createRef, useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@mui/material";
import './PopUp.scss';

export default function PopUp() {
    const reRef = createRef();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        email: '',
        fName: '',
        lName: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        zip: ''
    });
    const [errors, setErrors] = useState({});
    const [hasSubscribed, setHasSubscribed] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    
    useEffect(() => {
        const hasSeen = localStorage.getItem('popupSeen');
        if(!hasSeen)
        {
            setTimeout(function() {
                handleOpen();
                window.localStorage.setItem('popupSeen', 'true');
            }, 10000)
        }
    }, []);

    function handleInput(e) {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    };
    
    function handleOnlyTextInput(e) {
        const value = e.target.value.replace(/[^a-zA-Z]+$/g, '');
        setState({
            ...state,
            [e.target.name]: value
        });
    };

    function handleOnlyNumberInput(e) {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length <= 5) {
            setState({
                ...state,
                [e.target.name]: onlyNums
            });
        }
    };

    function handlePhoneInput(e) {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setState({
                ...state,
                [e.target.name]: onlyNums
            });
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );
            setState({
                ...state,
                [e.target.name]: number
            });
        }
    }

    const validate = () => {
        let temp = {...errors};
        //email error validation
        if ('email' in state)
            temp.email = state.email && /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(state.email) ? '' : 'Please enter a valid email address.';
        //first name error validation
        if (state.fName) {
            temp.fName = state.fName.length >= 2 && state.fName.length <= 50 ? '' : 'Please enter a valid name';
        } else if (!state.fName) {
            temp.fName = '';
        }
        //last name error validation
        if (state.lName) {
            temp.lName = state.lName.length >= 2 && state.lName.length <= 50 ? '' : 'Please enter a valid name';
        } else if (!state.lName) {
            temp.lName = '';
        }
        //phone number error validation
        if (state.phone) {
            temp.phone = state.phone.length === 14 ? '' : 'Please enter a valid phone number including area code.';
        } else if (!state.phone) {
            temp.phone = '';
        }
        //address error validation
        if (state.address) {
            temp.address = state.address.length >= 2 && state.address.length <= 100 ? '' : 'Please enter a valid address';
        } else if (!state.address) {
            temp.address = '';
        }    
        //city error validation
        if (state.city) {
            temp.city = state.city.length >= 2 && state.city.length <= 60 ? '' : 'Please enter a valid city';
        } else if (!state.city) {
            temp.city = '';
        }
        //state error validation
        if (state.state) {
            temp.state = state.state.length >= 2 && state.state.length <= 50 ? '' : 'Please enter a valid state';
        } else if (!state.state) {
            temp.state = '';
        }
        //zip code error validation
        if (state.zip) {
            temp.zip = state.zip.length >= 3 && state.zip.length <= 5 ? '' : 'Please enter a valid zip';
        } else if (!state.lName) {
            temp.zip = '';
        }

        setErrors({
            ...temp,
        })

        if (state)
            return Object.values(temp).every((x) => x === '');
    }

    async function submitEmail(e) {
        if (validate()) {
            const token = await reRef.current.executeAsync();
            if(token) {
                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({"body":{"email":state.email,"fName":state.fName,"lName":state.lName,"phone":state.phone,"address":state.address,"city":state.city,"state":state.state,"zip":state.zip,"token":token}}),
                };
        
                fetch(`https://sun6lhz025.execute-api.us-east-2.amazonaws.com/prod/signup`, requestOptions)
                .catch(err => console.log(err));
        
                setState({...state,  email: '', fName: '', lName: '', phone: '', address: '', city: '', state: '', zip: ''});
                setHasSubscribed(true);
            }
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                className="popup-root"
            >
                {!hasSubscribed ? 
                    <div>
                        <DialogTitle className="popup-title">
                            Want to join our Email Blast?
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className="popup-text">
                                Our Email Blast includes News, Special Offers, Exclusives and More.
                            </DialogContentText>
                            <DialogContentText className="popup-text popup-note">
                                * Note we only require email but by filling out the other options gives us more details to easily contact you for giveaways and other exclusives. We will never sell your data.
                            </DialogContentText>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField 
                                        label="Email Address"
                                        type="email"
                                        name="email"
                                        value={state.email}
                                        onChange={handleInput}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        fullWidth
                                        required
                                        className="popup-textfield"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        label="First Name"
                                        type="text"
                                        name="fName"
                                        value={state.fName}
                                        onChange={handleOnlyTextInput}
                                        error={!!errors.fName}
                                        helperText={errors.fName}
                                        className="popup-textfield"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField 
                                        label="Last Name"
                                        type="text"
                                        name="lName"
                                        value={state.lName}
                                        onChange={handleOnlyTextInput}
                                        error={!!errors.lName}
                                        helperText={errors.lName}
                                        className="popup-textfield"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        label="Phone Number"
                                        type="tel"
                                        name="phone"
                                        value={state.phone}
                                        onChange={handlePhoneInput}
                                        error={!!errors.phone}
                                        helperText={errors.phone}
                                        fullWidth
                                        className="popup-textfield"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField 
                                        label="Address"
                                        type="text"
                                        name="address"
                                        value={state.address}
                                        onChange={handleInput}
                                        error={!!errors.address}
                                        helperText={errors.address}
                                        fullWidth
                                        className="popup-textfield"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField 
                                        label="City"
                                        type="text"
                                        name="city"
                                        value={state.city}
                                        onChange={handleOnlyTextInput}
                                        error={!!errors.city}
                                        helperText={errors.city}
                                        className="popup-textfield"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField 
                                        label="State"
                                        type="text"
                                        name="state"
                                        value={state.state}
                                        onChange={handleOnlyTextInput}
                                        error={!!errors.state}
                                        helperText={errors.state}
                                        className="popup-textfield"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField 
                                        label="Zip Code"
                                        type="tel"
                                        name="zip"
                                        value={state.zip}
                                        onChange={handleOnlyNumberInput}
                                        error={!!errors.zip}
                                        helperText={errors.zip}
                                        className="popup-textfield"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <ReCAPTCHA 
                                sitekey='6LdZuAgeAAAAAOkh_t7cC6IGjKZnIehBj5eWhVwY'
                                size="invisible"
                                badge="inline"
                                ref={reRef}
                                className="contact-captcha"
                            />
                        </DialogContent>   
                        <DialogActions>
                            <Button onClick={handleClose} className="popup-button">Close</Button>
                            <Button onClick={submitEmail} className="popup-button">Subscribe</Button>
                        </DialogActions>
                    </div>
                    :
                    <div>
                        <DialogTitle className="popup-title">
                            Thank you for subscribing!
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className="popup-text">
                                Your subscribing request went through successfully. Keep an eye on your inbox for our special offers and more.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} className="popup-button">Close</Button>
                        </DialogActions>
                    </div>
                }
            </Dialog>
        </div>
    )
}