
import React from 'react';
import './Footer.scss';
import { Container, Grid, List, ListItem, ListItemText, ListItemAvatar, Typography, Fab, Avatar, Divider } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TiktokIcon from '../../imgs/tiktok.svg';
import Icon from '@mui/material/Icon';

export default function Footer(){
    function GetCurrentYear() {
        var today = new Date();
        var year = today.getFullYear();
        return (
            <div>&copy; {year} Fresno Oxygen. All Rights Reserved.</div>
        );
    }

    return(
        <div className="footerRoot"> 
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h6">Any Questions? Contact Us!</Typography>
                        <List>
                            <ListItem className="footerAddressWrapper">
                                <a href="https://goo.gl/maps/31jK1Q84wjNNyLyz6" target="_blank" rel="noopener noreferrer" className="footerLink">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <RoomIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </a>
                                <ListItemText primary="Address" secondary="2825 S. Elm Ave #101 Fresno, CA 93706" />
                            </ListItem>
                            <ListItem>
                                <a href="tel:800-404-9353" target="_blank" rel="noopener noreferrer" className="footerLink">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PhoneIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </a>
                                <ListItemText primary="Phone" secondary="1 (800) 404-9353" />
                            </ListItem>
                            <ListItem>
                                <a href="mailto:info@barneswelding.com" target="_blank" rel="noopener noreferrer" className="footerLink">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <MailOutlineIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                </a>
                                <ListItemText primary="Email" secondary="info@barneswelding.com" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h6">Connect with Us!</Typography>
                        {/* <Typography className="subheader">Join our monthly email blast for new offers and specials.</Typography>
                        <form action="https://barnesweldingsupply.us2.list-manage.com/subscribe/post?u=444bf5f0202ce9b8086466976&amp;id=3fe5595aa1" method="post" name="mc-embedded-subscribe-form" target="_blank" noValidate> 
                            <Grid container direction="row">
                                <Grid item>
                                    <TextField 
                                    id="outlined-basic"
                                    label="Enter Email"
                                    margin="normal"
                                    variant="outlined"
                                    className="email-enter"
                                    type="email"
                                    name="EMAIL"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" size="large" type="submit" name="subscribe" className="email-submit">
                                    Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form> */}
                        <Grid container direction="row">
                            <Grid item>
                                <Fab className="fabIcon" href="https://www.facebook.com/barneswelding/" target="_blank">
                                    <FacebookIcon />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab className="fabIcon" href="https://www.instagram.com/barnesweldingsupply/" target="_blank">
                                    <InstagramIcon />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab className="fabIcon" href="https://www.tiktok.com/@barnesweldingsupply/" target="_blank">
                                    <Icon><img src={TiktokIcon} alt="tiktok icon" className="tiktokIcon" /></Icon>
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab className="fabIcon" href="https://www.youtube.com/@BarnesWeldingSupply" target="_blank">
                                    <YouTubeIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Typography variant="h6">13 Locations to Serve You!</Typography>
                        <Typography className="subheader"><a href="/locations#1" className="footerLink">Fresno</a> &bull; <a href="/locations#7" className="footerLink">Bakersfield</a> &bull; <a href="/locations#4" className="footerLink">Clovis</a> </Typography>
                        <Typography className="subheader"><a href="/locations#6" className="footerLink">Citrus Heights</a> &bull; <a href="/locations#5" className="footerLink">Madera</a> &bull; <a href="/locations#10" className="footerLink">Modesto</a> </Typography>
                        <Typography className="subheader"><a href="/locations#3" className="footerLink">Porterville</a> &bull; <a href="/locations#8" className="footerLink">Sacramento</a> &bull; <a href="/locations#13" className="footerLink">Salinas</a> </Typography>
                        <Typography className="subheader"><a href="/locations#11" className="footerLink">Stockton</a> &bull; <a href="/locations#12" className="footerLink">Turlock</a> &bull; <a href="/locations#2" className="footerLink">Visalia</a> </Typography>
                        <Typography className="subheader"><a href="/locations#9" className="footerLink">West Sacramento</a></Typography>
                        <Divider className="dividerSpacing" />
                        <Typography className="copyright" variant="subtitle2"><GetCurrentYear></GetCurrentYear></Typography>
                        <a href="https://www.bbb.org/us/ca/fresno/profile/welding-supplies/fresno-oxygen-and-welding-suppliers-inc-1066-21002596/#sealclick" target="_blank" rel="noreferrer"><img src="https://seal-cencal.bbb.org/seals/blue-seal-250-52-whitetxt-bbb-21002596.png" sx={{border: 0}} alt="Fresno Oxygen and Welding Suppliers, Inc. BBB Business Review" /></a>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}