import React from 'react';
import './RentalEquipment.scss';
import rentalfiller from '../../imgs/rentalequipmentfiller.jpg';
import { Grid, Container, Paper, Typography } from '@material-ui/core';

export default function RentalEquipment() {
    return (
        <div className="rentalRoot">
            <Grid item xs={12} className="rentalHero">
                <Typography variant="h2" className="subtitle2">
                    Rental Equipment
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <div className="rentalContainer">
                    <Grid item xs={12}>
                        <Paper square className="rentalPaper subtitle3">
                            <Typography variant="h5">Do you need equipment for a day, a week or even a month? Don't worry we have you covered!</Typography>
                        </Paper>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Paper square className="rentalPaper sameHeight">
                                <Typography variant="h5" className="rentalSubtitle">Looking to rent?</Typography>
                                <Typography>Don't have a piece of equipment for a quick job or project? Barnes Welding Supply carries a full line of welding equipment for rent whether its for a day, week or even a month! We'll deliver it where you need it and pick up when you are finished.</Typography>
                                <Typography>Contact your local store for more information.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Paper square className="rentalPaper sameHeight">
                                <Grid container>
                                    <Grid item sm={6} xs={12}>
                                        <Typography variant="h5" className="rentalSubtitle">We Rent:</Typography>
                                        <ul>
                                            <li><Typography>110V MIG Welders</Typography></li>
                                            <li><Typography>230V MIG Welders</Typography></li>
                                            <li><Typography>TIG Welders</Typography></li>
                                            <li><Typography>Portable Arc Welder-Generators</Typography></li>
                                            <li><Typography>Plasma Cutting Equipment</Typography></li>
                                            <li><Typography>Fume Extractors</Typography></li>
                                        </ul>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <img src={rentalfiller} alt="Rental Equipment Filler" className="imagefix"/>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}