import React from 'react';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { PageTitle } from './components/PageTitle/PageTitle';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import SDSManuals from './components/SDSManuals/SDSManuals';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Financing from './components/Financing/Financing';
import EmployeeDirectory from './components/EmployeeDirectory/EmployeeDirectory';
import Locations from './components/Locations/Locations';
import CareerOpportunities from './components/CareerOpportunities/CareerOpportunities';
import WeldingIndustrialSupplies from './components/WeldingIndustrialSupplies/WeldingIndustrialSupplies';
import ServiceDepartmentRepairs from './components/ServiceDepartmentRepairs/ServiceDepartmentRepairs';
import Gas from './components/Gas/Gas';
import CylinderManagement from './components/CylinderManagement/CylinderManagement';
import RentalEquipment from './components/RentalEquipment/RentalEquipment';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ToTopButton from './components/ToTopButton/ToTopButton';
import "./components/Home/FeaturedManufacturers/slick.scss";
import "./components/Home/FeaturedManufacturers/slick-theme.scss";
import './App.scss';
import PopUp from './components/PopUp/PopUp';

require('dotenv').config();

const trackingId = "G-3EN18LSYSX";
ReactGA.initialize(trackingId, {
  gaOptions: {
      cookieFlags: 'SameSite=None; Secure'
  }
});

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const withTitle = ({ component: Component, title }) => {
  return class Title extends React.Component {
      render() {
          return (
              <React.Fragment>
                  <PageTitle title={title} />
                  <Component {...this.props} />
              </React.Fragment>
          );
      }
  };
};

const HomeComponent = withTitle({ component: Home, title: 'Home' });
const WeldingIndustrialSuppliesComponent = withTitle({ component: WeldingIndustrialSupplies, title: 'Welding Industrial Supplies' });
const GasComponent = withTitle({ component: Gas, title: 'Gas Services' });
const CylinderManagementComponent = withTitle({ component: CylinderManagement, title: 'Cylinder Management' });
const ServiceDepartmentRepairsComponent = withTitle({ component: ServiceDepartmentRepairs, title: 'Service Department & Repairs' });
const RentalEquipmentComponent = withTitle({ component: RentalEquipment, title: 'Rental Equipment' });
const SDSManualsComponent = withTitle({ component: SDSManuals, title: 'SDS & Manuals' });
const AboutComponent = withTitle({ component: About, title: 'About Us' });
const EmployeeDirectoryComponent = withTitle({ component: EmployeeDirectory, title: 'Employee Directory' });
const LocationsComponent = withTitle({ component: Locations, title: 'Locations' });
const CareerOpportunitiesComponent = withTitle({ component: CareerOpportunities, title: 'Career Opportunities' });
const ContactComponent = withTitle({ component: Contact, title: 'Contact Us' });
const FinancingComponent = withTitle({ component: Financing, title: 'Financing Options' });


function App() {
  return (
    <div>
      <Router history={history}>
        <PopUp />
        <NavBar />
        <Route exact path="/" component={HomeComponent} />
        <Route path="/welding-industrial-supplies" component={WeldingIndustrialSuppliesComponent} />
        <Route path="/gas-services" component={GasComponent} />
        <Route path="/cylinder-management" component={CylinderManagementComponent} />
        <Route path="/service-department-repairs" component={ServiceDepartmentRepairsComponent} />
        <Route path="/rental-equipment" component={RentalEquipmentComponent} />
        <Route path="/sds-manuals" component={SDSManualsComponent} />
        <Route path="/about" component={AboutComponent} />
        <Route path="/employee-directory" component={EmployeeDirectoryComponent} />
        <Route path="/locations" component={LocationsComponent} />
        <Route path="/career-opportunities" component={CareerOpportunitiesComponent} />
        <Route path="/contact" component={ContactComponent} />
        <Route path="/financing" component={FinancingComponent} />
        <ToTopButton />
        <Footer />
        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;