import './EmployeeDirectory.scss';
import React from 'react';
import { Grid, Container, Paper, Typography, Avatar, ButtonGroup, Button, TextField } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmployeeDirectoryData from './EmployeeDirectoryData';

export default function EmployeeDirectory() {
    const [value, setValue] = React.useState('');
    const [listEnd, setEnd] = React.useState('9');
    const [listStart, setStart] = React.useState('0');

    const handleSearchChange = (event) => {   
        setEnd(9);
        setStart(0);
        setValue(event.target.value);
        setActive(1);
    };

    const includeCheck = (data, check_val) => {
        return data.toUpperCase().includes(check_val.trim().toUpperCase());
    };

    const dataCeiled = Math.ceil(EmployeeDirectoryData.filter(EmployeeDirectoryData => includeCheck(EmployeeDirectoryData.name, value) || includeCheck(EmployeeDirectoryData.title, value) || includeCheck(EmployeeDirectoryData.location, value ) || value === "").length / 9);
    const numberOfButtons = Array.from({length: dataCeiled}, (e, i) => i + 1);
    const [activeButton, setActive] = React.useState(numberOfButtons[0]);

    const buttonClick = (b) => {
        const buttonNumber = b.currentTarget.textContent;
        setEnd(buttonNumber * 9);
        setStart(buttonNumber * 9 - 9);
        setActive(buttonNumber * 1);
    };

    return (
        <div className="employeeRoot">
            <Grid item xs={12} className="employeeHero">
                <Typography variant="h2" className="heroSub">
                    Employee Directory
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <div className="employeeListRoot">
                    <Paper square className="employeeListHeaderPaper">
                        <Typography className="employeeListHeader" variant="h4">Search through our directory to get details and contact information!</Typography>
                        <TextField 
                            className="employeeSearch"
                            label="Employee Search" 
                            variant="outlined" 
                            onChange={handleSearchChange}
                            value={value}
                        />
                    </Paper>    
                    <Grid container spacing={1}>
                        {EmployeeDirectoryData.filter(EmployeeDirectoryData => includeCheck(EmployeeDirectoryData.name, value) || includeCheck(EmployeeDirectoryData.title, value) || includeCheck(EmployeeDirectoryData.location, value ) || value === "").slice(listStart,listEnd).map((ED, index) => (   
                            <Grid item md={4} xs={12} key={index}>
                                <Paper square className="elPaper">
                                    <Avatar src={ED.img} alt={ED.name} className="elImg"></Avatar>
                                    <Typography variant="h4" className="elName">{ED.name}</Typography>
                                    <Typography variant="h5">{ED.title}</Typography>
                                    <Typography className="elSub"><PhoneIcon className="elIcon" />{ED.phone}</Typography>
                                    <Typography className="elSub"><MailOutlineIcon className="elIcon" />{ED.email}</Typography>
                                    <Typography className="elSub"><BusinessIcon className="elIcon" />{ED.location}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                    <ButtonGroup className="employeeButtonGroup">
                        {numberOfButtons.map((bt, index) => (
                            // eslint-disable-next-line
                            <Button onClick={buttonClick} key={index} className={activeButton === index + 1 ? "employeeActiveButton" : "" + " employeeButton"}>{bt}</Button>
                        ))}
                    </ButtonGroup>
                </div>
            </Container>
        </div>
    )
}