import React from 'react';
import './CylinderManagement.scss';
// import ACMStepper from './ACMStepper/ACMStepper';
import { Grid, Container, Paper, Typography } from '@material-ui/core';

export default function CylinderManagement() {
    return (
        <div className="cylinderRoot">
            <Grid item xs={12} className="cylinderHero">
                <Typography variant="h2" className="subtitle2">
                    Cylinder Management (ACM)
                </Typography>
            </Grid>
            <Container maxWidth="lg" className="cylinderManagementContainer">
                <div className="cylinderContent">
                    <Grid item xs={12}>
                        <Paper square className="cylinderPaper subtitle3">
                            <Typography variant="h5" className="cylinderSubtitle"> Cylinder Asset Management</Typography>
                            <Typography className="white">Keeping track of your cylinder assets can be a major chore. We know cylinders can get moved, mixed up, or even lost. With Advanced Cylinder Management (ACM) we give you the ability to accurately track each cylinder that is delivered and returned from your account. Starting from the minute the cylinder is filled, to the time you receive it, we know the status and location of every cylinder.</Typography>
                        </Paper>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Paper square className="cylinderPaper sameHeight justifying">
                                <Typography variant="h5" className="cylinderSubtitle">How do we do this?</Typography>
                                <Typography>We have affixed a Cylinder Tag ID to our cylinders. During each transaction that Tag ID is scanned to record in real time, the movement of that cylinder. As the Tag ID is scanned it is recorded in our system and documented on your receipt. That same Tag ID will show up on your invoices and on your statements.</Typography>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Paper square className="cylinderPaper sameHeight">
                                <Typography variant="h5" className="cylinderSubtitle">How does this benefit you?</Typography>
                                <Typography>Because of ACM you will have confidence that:</Typography>
                                <ul>
                                    <li>You will save time and money knowing exactly what cylinders you have and where they are.</li>
                                    <li>Your account is being billed accurately.</li>
                                    <li>The number is cylinder errors will be reduced.</li>
                                </ul>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                {/* <Grid item xs={12}>
                    <ACMStepper />
                </Grid> */}
            </Container>
        </div>
    )
}