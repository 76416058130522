import React from 'react';
import './Financing.scss';
import { Grid, Container, Typography, Paper, Button } from '@material-ui/core';
import peac from '../../imgs/peac-solutions.svg'
import newlane from '../../imgs/newlane-finance.svg'
import powerline from '../../imgs/PowerLINE-header-2.jpg'
import synchrony from '../../imgs/Synchrony_Logo.png'
import synchronyPDF from '../../pdfs/Synchrony-Financing-Sign.pdf'

export default function Financing() {
  return (
    <div className="financingRoot">
      <Grid item xs={12} className="financingHead">
        <Typography variant="h2" className="subtitle2">Financing Options</Typography>
      </Grid>
      <Container maxWidth="lg" >
        <Grid container spacing={2} align="center" justify="center" alignItems="center" className="financingContent">
          <Grid item xs={12}>
            <Typography variant="h3">We have multiple financing options to help you!</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper square elevation={3} className="financingWrapper">
              <div className="financingImgWrapper">
                <img src={peac} alt="peac solutions" className="financingImg" />
              </div>                 
              <Typography variant="h5" className='financingTitle'>Make payments easy with PEAC Solutions</Typography>
              <ul>
                <li><Typography>Flexible Financing</Typography></li>
                <li><Typography>Dedicated Guidance</Typography></li>
                <li><Typography>Fast & Convenient Processes</Typography></li>
              </ul>
              <Button variant="contained" color="primary" href="https://apply.peacsolutions.com/auth/new-business" target="_blank" className='financingButton'>Click To Apply!</Button>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper square elevation={3} className="financingWrapper">
              <div className="financingImgWrapper">
                <img src={newlane} alt="newlane finance" className="financingImg" />
              </div>                 
              <Typography variant="h5" className='financingTitle'>Make payments easy with NewLane Finance</Typography>
              <ul>
                <li><Typography>Quick & Easy Process</Typography></li>
                <li><Typography>Fair & Transparent Terms</Typography></li>
                <li><Typography>Customer-First Approach</Typography></li>
              </ul>
              <Button variant="contained" color="primary" href="https://newlanefinance.com/finance-application/" target="_blank" className='financingButton'>Click To Apply!</Button>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper square elevation={3} className="financingWrapper">
              <div className="financingImgWrapper">
                <img src={powerline} alt="miller powerline" className="financingImg" />
              </div>                 
              <Typography variant="h5" className='financingTitle'>0% Financing for B2B Customers MILLER POWERLINE FINANCE PROGRAM</Typography>
              <ul>
                <li><Typography>This 0% financing program through Wells Fargo makes it easy to get the machines you need now.</Typography></li>
                <li><Typography>Qualified business borrowers get 0% financing for 12 months (nominal rates for longer terms up to 60 months) and 0% for 24 months on Miller Engine Drives ONLY (nominal rates for longer terms up to 60 months).</Typography></li>
              </ul>
              <Button variant="contained" color="primary" href="mailto:info@barneswelding.com" target="_blank" rel="noopener noreferrer" className='financingButton'>Contact to learn more!</Button>
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper square elevation={3} className="financingWrapper">
              <div className="financingImgWrapper">
                <img src={synchrony} alt="synchrony" className="financingImg" />
              </div>                 
              <Typography variant="h5" className='financingTitle'>6 or 12 Months Deferred Interest on all Products Instores!</Typography>
              <ul>
                <li><Typography>Save money with deferred interest instead of using your higher rate credit card! Apply for a Synchrony credit account! </Typography></li>
                <li><Typography>We offer financing from Synchrony at all store locations! Speak with a store associate for more details on how to apply. Subject to credit approval.</Typography></li>
              </ul>
              <Button variant="contained" color="primary" href={synchronyPDF} target="_blank" rel="noopener noreferrer" className='financingButton'>Click to learn more!</Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}