import React from 'react';
import './ServiceDepartmentRepairs.scss';
import AuthorizedManufacturers from './AuthorizedManufacturers/AuthorizedManufacturers';
import { Grid, Container, Paper, Typography } from '@material-ui/core';
import logo from '../../imgs/bws-logo-simple.svg';

export default function ServiceDepartmentRepairs() {
    return (
        <div className="repairRoot">
            <Grid item xs={12} className="repairHero">
                <Typography variant="h2" className="subtitle2">
                    Service Department & Repairs
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <div className="repairContainer">
                    <Grid container spacing={1}>
                        <Grid item md={6} xs={12}>
                            <Paper square className="repairPaper">
                               <div className="insidePadding">
                                    <Typography variant="h5" className="repairSubtitle">Need your welder Serviced or Repaired?</Typography>
                                    <Typography className="wisContent" component="p">
                                        Why not give us a call and let our FACTORY TRAINED and CERTIFIED technicians keep your welding equipment running smooth. Bring your equipment to any of our twelve retail locations or give us a call and we can come to you!
                                    </Typography>
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Paper square className="repairPaper">
                                <div className="insidePadding">
                                    <Typography variant="h5" className="repairSubtitle">Can't get your welder to us?</Typography>
                                    <Typography className="wisContent" component="p">
                                        Don't worry, Field Service is available. Whether it is at the jobsite or at your place or business, we can send a technician out to you.
                                    </Typography>
                                    <Typography className="wisContent gray" component="p">
                                        * Field Service is available at an additional charge.
                                    </Typography>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="serviceMFG">
                        <Paper square className="repairPaper insidePadding">

                            <Typography variant="h5" className="repairSubtitle">Factory Authorized Service & Warranty Repair Manufacturers</Typography>
                            <AuthorizedManufacturers />
                            <Typography component="p">
                                We are FACTORY AUTHORIZED for WARRANTY REPAIRS on Miller Electric, Lincoln Electric, ESAB, Thermal Dynamics, Hypertherm, Thermal Arc, Hobart, MK Products (Cobar) and many other major brands.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} className="serviceMFG">
                        <Paper square className="repairPaper2">
                            <Typography variant="h5" className="repairSubtitle">Service Department Information</Typography>
                            <Grid container spacing={1}>
                                <Grid item md={4} xs={12}>
                                    <img src={logo} alt="bws-logo" className="serviceLogo"/>
                                </Grid>
                                <Grid item md={8} xs={12} container direction="row" justify="center" alignItems="center">
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="h5" className="repairSubtitle">Monday - Friday</Typography>
                                        <Typography variant="h5">8:00 AM to 5:00 PM</Typography>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Typography variant="h5" className="repairSubtitle">Phone: (559) 233-6684</Typography>
                                        <Typography variant="h5" className="repairSubtitle">Fax: (559) 233-4294</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" className="repairSubtitle">Email: shop@barneswelding.com</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}