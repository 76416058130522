import React from 'react'
import './CareerOpportunities.scss'
import {
  Grid,
  Container,
  Typography,
  Paper,
  Card,
  CardContent,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import employment from '../../imgs/employment.svg'
import employmentApplication from '../../pdfs/HR-02 - Employment Application.pdf'
import CareerOpportunitiesData from './CareerOpportunitiesData'

export default function CareerOpportunities() {
  return (
    <div className='careerOpportunitiesRoot'>
      <Grid item xs={12} className='careerOpportunitiesHero'>
        <Typography variant='h2' className='heroSub'>
          Career Opportunities
        </Typography>
      </Grid>
      <Container maxWidth='lg'>
        <Grid item xs={12} className='careerOpportunitiesContent'>
          <Paper square className='careerOpportunitiesPaper'>
            <Typography variant='h4' className='careerOpportunitiesSub'>
              Are you interested to work for Barnes Welding Supply?
            </Typography>
            <Grid container spacing={1}>
              <Grid
                item
                md={6}
                xs={12}
                className='careerOpportunitiesImgWrapper'
              >
                <Link to={employmentApplication} target='_blank'>
                  <img
                    src={employment}
                    alt='employment'
                    className='careerOpportunitiesImg'
                  />
                </Link>
                <Typography variant='h5' className='careerOpportunitiesImgSub'>
                  Fill out an application!
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Card square className='careerOpportunitiesInfoCard'>
                  <CardContent>
                    <Typography
                      variant='h5'
                      component='h2'
                      className='careerOpportunitiesCardSub'
                    >
                      Please contact our Human Resources Department concerning
                      any open position listed below.
                    </Typography>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <Typography variant='body2' component='p'>
                          <b>Contact:</b> Jeff Darneal <br /> Human Resources
                        </Typography>
                        <Typography variant='body2' component='p'>
                          <b>Phone:</b> (559) 233-6684
                        </Typography>
                        <Typography variant='body2' component='p'>
                          <b>Email Resume:</b> hr@barneswelding.com
                        </Typography>
                        <Typography variant='body2' component='p'>
                          <b>Fax Resume:</b> (559) 441-1172
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography variant='body2' component='p'>
                          <b>Apply in Person:</b> Barnes Welding Supply
                          Corporate Office <br /> 2825 S. Elm Ave. #101 <br />{' '}
                          Fresno, CA 93706-5460
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              className='careerOppurtinitesListWrapper'
            >
              {CareerOpportunitiesData.map((CO, i) => (
                <Grid item md={4} xs={12} key={i}>
                  <Card square className='careerOpportunitiesCard'>
                    <CardContent>
                      <Typography
                        variant='h5'
                        component='h2'
                        className='careerOpportunitiesCardSub'
                      >
                        {CO.jobTitle}
                      </Typography>
                      <Typography variant='body2' component='p'>
                        <b>Job Type:</b> {CO.jobType}
                      </Typography>
                      <Typography variant='body2' component='p'>
                        <b>Location: </b>
                        {CO.location}
                        <br />
                        {CO.address}
                        <br />
                        {CO.zip}
                      </Typography>
                      <Typography variant='body2' component='p'>
                        <b>Deadline:</b> {CO.deadline} <br />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Typography className='careerOpportunitiesLastUpdated'>
              Last Updated: 7/17/2024
            </Typography>
          </Paper>
        </Grid>
      </Container>
    </div>
  )
}
