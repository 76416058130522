import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';
import { Hidden, AppBar, Toolbar, Container, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Collapse, Typography, Button, IconButton, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export default function NavBar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dropdown, setDropdown] = React.useState(null);
    const [opendrop, setOpen] = React.useState("");
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer = (side, open) => event => {
        setState({ ...state, [side]: open });
    };

    const handleDropClick = index => {
        if(opendrop === index) {
            setOpen("");
        } else {
            setOpen(index);
        }
    };

    const handleMenuClick = event => {
        setDropdown(event.currentTarget.textContent);
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const navLinks = [
        { 'title': 'Home', 'link': '/', 'submenu': null },
        { 
            'title': 'Services', 
            'link': null,
            'submenu': [
                { 'title': 'Welding & Industrial Supplies', 'link': '/welding-industrial-supplies', 'submenu': null },
                { 'title': 'Gas Services', 'link': '/gas-services', 'submenu': null },
                { 'title': 'Cylinder Management (ACM)', 'link': '/cylinder-management', 'submenu': null },
                { 'title': 'Service Department & Repairs', 'link': '/service-department-repairs', 'submenu': null },
                { 'title': 'Rental Equipment', 'link': '/rental-equipment', 'submenu': null },
            ]
        },
        { 'title': 'Financing', 'link': '/financing', 'submenu': null },
        { 
            'title': 'About', 
            'link': null, 
            'submenu': [
                { 'title': 'Who We Are', 'link': '/about', 'submenu': null },
                { 'title': 'SDS & Manuals', 'link': '/sds-manuals', 'submenu': null },
                { 'title': 'Employee Directory', 'link': '/employee-directory', 'submenu': null },
                { 'title': 'Locations', 'link': '/locations', 'submenu': null },
                { 'title': 'Career Opportunities', 'link': '/career-opportunities', 'submenu': null},
            ] 
        },
        { 'title': 'Contact', 'link': '/contact', 'submenu': null },
    ]

    const sideDrawer = side => (
        <div
        className="list"
        role="presentation"
        >
            <List>
                <ListItem component={Button} href="https://ar.barnesweldingsupply.com/" className='ecommerceButton list-link'>
                    <ListItemText>Customer Portal</ListItemText>
                </ListItem>
                <Divider />
                {navLinks.map((e, index) => (
                    <div key={index} className="test1">
                        { e.submenu ? (
                            <div>
                                <ListItem button onClick={() => handleDropClick(index)}>
                                    <ListItemText primary={e.title} />
                                    { index === opendrop ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={index === opendrop} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {e.submenu.map((f, index) => (
                                            <div key={index}>
                                                <Divider />
                                                <ListItem button onClick={toggleDrawer(side, false)} component={Link} to={f.link}>
                                                    <ListItemText primary={f.title}/>
                                                </ListItem>
                                            </div>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ) : (
                            <ListItem button onClick={toggleDrawer(side, false)} component={Link} to={e.link}>
                                <ListItemText primary={e.title}/>
                            </ListItem>
                        )}
                        <Divider />
                    </div>
                ))}
            </List>
        </div>
    );
    
    return (
        <div className="navroot">
            <AppBar position="static">
                <Container maxWidth="lg" className="container">
                    <Toolbar>
                        <Hidden smUp>
                            <IconButton edge="start" color="inherit" onClick={toggleDrawer('left', true)}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                                {sideDrawer('left')}
                            </Drawer>
                        </Hidden>
                        <Typography className="title" variant="h6" noWrap>
                            Barnes Welding Supply
                        </Typography>
                        <Hidden xsDown>
                            {navLinks.map((e, index) => (
                                <div key={index}>
                                    { e.submenu ? (
                                        <div className="navlinkswrapper">
                                            <Button color="inherit" onClick={handleMenuClick}>
                                                {e.title}
                                            </Button>   
                                            { e.title === dropdown ? (
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        horizontal: 'center',
                                                        vertical: 'bottom',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                    getContentAnchorEl={null}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                    className="navpopout"
                                                >
                                                    {e.submenu.map((f, index) => (
                                                        <div key={index}>
                                                                <div>
                                                                    <MenuItem component={Link} to={f.link}>
                                                                        <ListItemText primary={f.title} />
                                                                    </MenuItem>
                                                                    <Divider />
                                                                </div>
                                                        </div>
                                                    ))}
                                                </Menu>
                                            ) : (
                                                <div></div>
                                            )}     
                                        </div>                            
                                    ) : (
                                        <Button color="inherit" component={Link} to={e.link}>
                                            {e.title}
                                        </Button>
                                    )}
                                </div>
                            ))}
                                <Button href="https://ar.barnesweldingsupply.com/" className="ecommerceButton">Customer Portal</Button>
                        </Hidden>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );  
}