import React, { Component } from "react";
import './FeaturedManufacturers.scss';
import Slider from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography } from '@material-ui/core';
import MLR from "../../../imgs/brands/MLR-Miller-Logo.svg";
import LNC from "../../../imgs/brands/LNC-Lincoln-Electric-logo.svg";
import HYP from "../../../imgs/brands/HYP_Hypertherm_Logo.svg";
import ESB from "../../../imgs/brands/ESB-ESAB-Logo.svg";
import VIC from "../../../imgs/brands/VIC-Victor-logo.svg";
import TDC from "../../../imgs/brands/TDC-Thermal-Dynamics.svg";
import TWE from "../../../imgs/brands/TWE-Tweco-logo.svg";
import HAR from "../../../imgs/brands/HRS-Harris-Logo.svg";
import BSX from "../../../imgs/brands/REV-BSX-Logo.svg";
import MAK from "../../../imgs/brands/MAK-Makita-Logo.svg";
import DWT from "../../../imgs/brands/DWT-Dewalt-Logo.svg";
import REV from "../../../imgs/brands/REV-Black-Stallion_Logo.svg";
import HOB from "../../../imgs/brands/HOB-Hobart-Logo.svg";
import ABR from "../../../imgs/brands/ABR-United-Abrasives-Logo.svg";
import JAC from "../../../imgs/brands/JAC-Jackson-Logo.svg";
import PRL from "../../../imgs/brands/PRL-Pearl-Abrasive-Logo.svg";
import IWD from "../../../imgs/brands/IWD-Weldmark-Logo.svg";
import MAT from "../../../imgs/brands/MAT-Mathey-Dearman_Logo.svg";
import FTI from "../../../imgs/brands/FTI-Flame-Tech-Logo.svg";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
    <ArrowForwardIosIcon />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </div>
  );
}


export default class FeaturedManufacturers extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 5,
      speed: 1000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
      ]
    };
    return (
      <div className="MFGRoot">
        <Typography variant="h4" className="centerTitle">Featured Manufacturers</Typography>
        <Slider {...settings}>
          <div>
            <img src={HYP} alt="HYP"/>
          </div>
          <div>
            <img src={ESB} alt="ESB"/>
          </div>
          <div>
            <img src={VIC} alt="VIC"/>
          </div>
          <div>
            <img src={TDC} alt="TDC"/>
          </div>
          <div>
            <img src={TWE} alt="TWE"/>
          </div>
          <div>
            <img src={HAR} alt="HAR"/>
          </div>
          <div>
            <img src={BSX} alt="BSX"/>
          </div>
          <div>
            <img src={MAK} alt="MAK"/>
          </div>
          <div>
            <img src={DWT} alt="DWT"/>
          </div>
          <div>
            <img src={REV} alt="REV"/>
          </div>
          <div>
            <img src={HOB} alt="HOB"/>
          </div>
          <div>
            <img src={ABR} alt="ABR"/>
          </div>
          <div>
            <img src={JAC} alt="JAC"/>
          </div>
          <div>
            <img src={PRL} alt="PRL"/>
          </div>
          <div>
            <img src={IWD} alt="IWD"/>
          </div>
          <div>
            <img src={MAT} alt="MAT"/>
          </div>
          <div>
            <img src={FTI} alt="FTI"/>
          </div>
          <div>
            <img src={MLR} alt="MLR"/>
          </div>
          <div>
            <img src={LNC} alt="LNC"/>
          </div>
        </Slider>
      </div>
    );
  }
}