import React from 'react';
import PropTypes from 'prop-types';
import './Gas.scss';
import bsglogo from '../../imgs/bsg-logo.svg';
import tapslogo from '../../imgs/taps-logo.svg';
import bulkTrailer from '../../imgs/bulkTrailer.svg';
import dryIce from '../../imgs/services/dry-ice.svg';
import purityPlus from '../../imgs/PurityPlusLogo.svg';
import medicalRack from '../../imgs/medical-gases-rack.jpg';
import EDCylinders from '../../imgs/edcylinders.jpg';
import dave from '../../imgs/BSG_Van_Dave.jpg';
import fowlerAir from '../../imgs/fowler-plant-aerial.jpg';
import { Grid, Container, Paper, Tabs, Tab, Box, Typography } from '@material-ui/core';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            className="tabpanels"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
            )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export default function Gas() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="gasRoot">
            <Grid item xs={12} className="gasHero">
                <Typography variant="h2" className="subtitle2">
                    Gas Services
                </Typography>
            </Grid>
            <Container maxWidth="lg">
                <Grid item xs={12} className="gasServiceContainer">
                    <Paper square className="paperTabContainer">
                        <Grid container>
                            <Grid item md={2} xs={12}>
                                <Tabs 
                                    value={value} 
                                    onChange={handleChange} 
                                    orientation="vertical"
                                    className="tabsContainer" 
                                    variant="scrollable" 
                                    scrollButtons="on"
                                    aria-label="Vertical tabs example"
                                >
                                    <Tab label="Industrial Gases" {...a11yProps(0)} />
                                    <Tab label="Bulk Gases" {...a11yProps(1)} />
                                    <Tab label="Specialty Gases" {...a11yProps(2)} />
                                    <Tab label="Beverage CO2" {...a11yProps(3)} />
                                    <Tab label="Dry Ice" {...a11yProps(4)} />
                                </Tabs>
                            </Grid>
                            <Grid item md={10} xs={12}>
                                <TabPanel value={value} index={0}>
                                    <Typography variant="h4" className="tabTitle">Industrial Gases</Typography>
                                    <Typography>We are a full-service supplier of industrial & welding gases. We offer a full line of gases and a variety of gas packaging options to meet most any industry application. Our radio dispatched distribution network is always ready to meet your needs, ensuring that you get your gas products on time, every time. </Typography>
                                    <div><img src={fowlerAir} alt="fowler air" className="medicalRack"></img></div>
                                    <Typography variant="h5" className="spacingTop">Not sure what you need? We can help!</Typography>
                                    <ul>
                                        <li>Do you have the right industrial or welding gas for your application?</li>
                                        <li>Do you have the right gas package and delivery system to meet your needs?</li>
                                        <li>Let us help you optimize your process by having our team of account managers review your operation. We will make sure you have the right setup for the job.</li>
                                    </ul>
                                    <Typography variant="h5">We carry the following industrial gases</Typography>
                                    <Grid container>
                                        <Grid item md={4} xs={12}>
                                            <ul>
                                                <li>Acetylene</li>
                                                <li>Argon</li>
                                                <li>Argon Mix</li>
                                                <li>CO2</li>
                                            </ul>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <ul>
                                                <li>Compressed Air</li>
                                                <li>Fuel Gas</li>
                                                <li>Helium</li>
                                                <li>Hydrogen</li>
                                            </ul>
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <ul>
                                                <li>Industrial/Laser</li>
                                                <li>Nitrogen</li>
                                                <li>Oxygen</li>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <Grid container>
                                        <Box clone order={{ xs: 3, md: 2}}>
                                            <Grid item md={8} xs={12}>
                                                <Typography variant="h4" className="tabTitle">Bulk Gases</Typography>
                                                <Typography>Barnes Welding Supply owns and operates a fleet of bulk tanker trucks to service Bulk and Micro Bulk users. Switching from cylinders to bulk gas can be a cost saving alternative depending on your need and usage. We can monitor the tank levels through a cellular telemetry communication device. This device can alert you, the customer, and Industrial Source dispatch when tank readings reach a reorder point. We offer bulk deliveries of CO2, Oxygen, Nitrogen, and Argon.</Typography>
                                            </Grid>
                                        </Box>
                                        <Box clone order={{ xs: 2, md: 3}}>
                                            <Grid item md={4} xs={12}>
                                                <div className="center">
                                                    <img src={bulkTrailer} className="logosmaller2" alt="Bulk Trailer" />
                                                </div>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <Grid container>
                                        <Box clone order={{ xs: 3, md: 2}}>
                                            <Grid item md={8} xs={12}>
                                                <Typography variant="h4" className="tabTitle">Specialty Gases</Typography>
                                                <Typography>Barnes Welding Supply, through our division Barnes Specialty Gases, offers a full range of high purity specialty gases & mixtures for a wide array of businesses and industries. Our production facility is PURITY PLUS CERTIFIED allowing us to offer a unique line of certified specialty gas products. When the quality of the product and compliance with tight tolerances is critically important, you need high purity gases. We use highly advanced analytical equipment, thoroughly trained lab technicians, and the highest quality source gases to ensure our products meet customer and industry specifications.</Typography>
                                                <div><img src={dave} alt="dave chilling" className="medicalRack"></img></div>
                                                <Typography className="spacingTop">Our Barnes Specialty Gases sales team has the expertise and training to provide the technical support you need. Help us understand your specific application, so we can suggest the right product and packaging option.</Typography>
                                                <div><img src={EDCylinders} alt="E and D Cylinders" className="medicalRack"></img></div>
                                                <div><img src={medicalRack} alt="medical rack" className="medicalRack"></img></div>
                                                <Typography className="spacingTop">Want to learn more? Check out the <a href="https://barnesspecialtygases.com/" target="_blank" rel="noopener noreferrer" className="gasLink">Barnes Specialty Gases</a> website!</Typography>
                                                <Typography className="spacingTop">Find the product you need in our <a href="https://www.barnesspecialtygases-purityplus.com/" target="_blank" rel="noopener noreferrer" className="gasLink">Purity Plus Catalog</a> today!</Typography>
                                                <Typography className="spacingTop">Have questions? <br/> Contact our Barnes Specialty Gases Account Manager: Chinmoy Bose<br/>Phone: (559) 341-4456<br/>Email: chinmoyb@barneswelding.com</Typography>
                                            </Grid>
                                        </Box>
                                        <Box clone order={{ xs: 2, md: 3}}>
                                            <Grid item md={4} xs={12}>
                                                <div className="center">
                                                    <a href="https://barnesspecialtygases.com/" target="_blank" rel="noopener noreferrer"><img src={bsglogo} className="logosmaller" alt="Barnes Specialty Gases" /></a>
                                                </div>
                                                <div className="center">
                                                    <a href="https://www.barnesspecialtygases-purityplus.com/" target="_blank" rel="noopener noreferrer"><img src={purityPlus} className="logosmaller" alt="Purity Plus" /></a>
                                                </div>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Grid container>
                                        <Box clone order={{ xs: 3, md: 2}}>
                                            <Grid item md={8} xs={12}>
                                                <Typography variant="h4" className="tabTitle">Beverage CO2</Typography>
                                                <Typography>Barnes Welding Supply, through our Taps CO2 division, offers specialized bulk CO2 and nitrogen generation systems for the food & beverage industry. Our Taps CO2 division serves a wide array of restaurants, breweries, sports venues, bars, fast food chains, and convenience stores. Our Taps CO2 team has the knowledge and expertise to help you with all of your beverage carbonation and nitrogen generation system needs.</Typography>
                                                <iframe className="bulkVideo" height="350" src="https://www.youtube.com/embed/yMSvDt5V4es?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <Typography className="spacingTop">Why Beverage CO2? When dealing with beverages, it’s important to have the right amount of Carbon Dioxide (CO2), Nitrogen (N2), and pressure are being applied. The correct combination of CO2 and N2 applied to craft and draught beers produce perfect carbonation. CO2 alone provides the carbonation in soft beverages and determines a drink’s taste, smell, and carbonation level. An imbalanced blend of CO2 and N2 results in flat beer. Too little CO2 makes for a flat soft drink. All of which result in waste, dissatisfied customers, and potentially lost revenue.</Typography>
                                                <Typography className="spacingTop">Want to learn more? Check out the <a href="https://tapsco2.com/" target="_blank" rel="noopener noreferrer" className="gasLink">Taps CO2</a> website!</Typography>
                                            </Grid>
                                        </Box>
                                        <Box clone order={{ xs: 2, md: 3}}>
                                            <Grid item md={4} xs={12}>
                                                <div className="center">
                                                    <a href="https://tapsco2.com/" target="_blank" rel="noopener noreferrer"><img src={tapslogo} className="logosmaller" alt="TapsCO2 Logo" /></a>
                                                </div>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    <Grid container>
                                        <Box clone order={{ xs: 3, md: 2}}>
                                            <Grid item md={8} xs={12}>
                                                <Typography variant="h4" className="tabTitle">Dry Ice</Typography>
                                                <Typography>Barnes Welding Supply produces fresh Dry Ice daily!</Typography>
                                                <iframe className="bulkVideo" height="315" src="https://www.youtube.com/embed/V-T3txHQS_0?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                <Typography className="spacingTop">We sell Dry Ice in rice and pellets by the pound, bring in your cooler or rent a 500LB or 1200 LB bin from us at any location!</Typography>
                                                <Grid container>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography variant="h5" className="spacingTop">Consumer Applications:</Typography>
                                                        <ul>
                                                            <li>Bed Bug Removal</li>
                                                            <li>Broken Freezer/Refrigerator</li>
                                                            <li>Camping</li>
                                                            <li>Fishing/Hunting</li>
                                                            <li>Fog Visual Effect</li>
                                                            <li>Lures Mosquitos Away</li>
                                                            <li>School Science Experiments</li>
                                                        </ul>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <Typography variant="h5" className="spacingTop">Commercial Applications:</Typography>
                                                        <ul>
                                                            <li>Asphalt Cooling</li>
                                                            <li>Containment Tank Repair/Removal</li>
                                                            <li>Dry Ice Blasting</li>
                                                            <li>Freezing Fresh Fruits</li>
                                                            <li>Fresh Meat Processing</li>
                                                        </ul>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box clone order={{ xs: 2, md: 3}}>
                                            <Grid item md={4} xs={12}>
                                                <div className="center"><img src={dryIce} className="logosmaller1" alt="Dry ice" /></div>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Container>
        </div>
    )
}