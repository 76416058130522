import React, { Component } from "react";
import './AuthorizedManufacturers.scss';
import Slider from "react-slick";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MLR from "../../../imgs/brands/MLR-Miller-White-Logo.svg";
import LNC from "../../../imgs/brands/LNC-Lincoln-Electric-logo.svg";
import HYP from "../../../imgs/brands/HYP_Hypertherm_Logo.svg";
import ESB from "../../../imgs/brands/ESB-ESAB-Logo.svg";
import TDC from "../../../imgs/brands/TDC-Thermal-Dynamics.svg";
import HOB from "../../../imgs/brands/HOB-Hobart-White-Logo.svg";
import MKP from "../../../imgs/brands/MKP-MKProducts-Logo.svg";
import TAC from "../../../imgs/brands/TAC-Thermal-Arc-Logo.svg";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
    <ArrowForwardIosIcon />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <ArrowBackIosIcon />
    </div>
  );
}


export default class AuthorizedManufacturers extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 5,
      speed: 1000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
      ]
    };
    return (
      <div className="AuthorizeRoot">
        <Slider {...settings}>
          <div>
            <img src={HYP} alt="HYP"/>
          </div>
          <div>
            <img src={ESB} alt="ESB"/>
          </div>
          <div>
            <img src={TDC} alt="TDC"/>
          </div>
          <div>
            <img src={HOB} alt="HOB"/>
          </div>
          <div>
            <img src={MKP} alt="MKP"/>
          </div>
          <div>
            <img src={TAC} alt="TAC"/>
          </div>
          <div>
            <img src={MLR} alt="MLR"/>
          </div>
          <div>
            <img src={LNC} alt="LNC"/>
          </div>
        </Slider>
      </div>
    );
  }
}