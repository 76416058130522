import React, { Component } from 'react';
import './ToTopButton.scss';
import { Fab } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

let className="TopButton";


export default class ToTopButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
          is_visible: false
        };
    }
  
    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener("scroll", function(e) {
          scrollComponent.toggleVisibility();
        });
    }
  
    toggleVisibility() {
        if (window.pageYOffset > 200) {
            this.setState({
                is_visible: true
            });
          } else {
            this.setState({
              is_visible: false
            });
          }
    }
  
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
  
    render() {
        const { is_visible } = this.state;
        return (
            <div className={className}>
                {is_visible && (
                    <div onClick={() => this.scrollToTop()}>
                        <Fab className="ArrowIconWrapper">
                            <ArrowUpwardIcon />
                        </Fab>
                    </div>
                )}
            </div>
        );
    }
}