const CareerOpportunitiesData = [
  {
    jobTitle: 'Sales Manager',
    jobType: 'Full Time - Hourly',
    location: 'Fresno Headquarters',
    address: '2825 S. Elm Ave #101',
    zip: 'Fresno, CA 93706',
    deadline: 'Open until filled',
  },
  {
    jobTitle: 'Dry Ice Sales',
    jobType: 'Full Time - Hourly',
    location: 'Sacramento Storefront',
    address: '4218 Power Inn Rd.',
    zip: 'Sacramento, CA 95826',
    deadline: 'Open until filled',
  },
]

export default CareerOpportunitiesData
