import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Contact.scss';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, Paper, TextField, Button } from '@material-ui/core';
import credit from '../../imgs/credit-app.svg';
import creditApplication from '../../pdfs/92126-BWS-Application-for-Credit.pdf';
import termsAndConditions from '../../pdfs/BWS Website Terms & Conditions.pdf'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function Contact() {
    const [state, setState] = React.useState({
        fullName: '',
        email: '',
        message: '',
    });
    const [token, setToken] = React.useState('');
    const [isShow, setIsShow] = React.useState(true);
    const recaptchaRef = React.createRef();

    function handleChange(event) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    };

    function captchaChange(value) {
        setToken(value);
    }

    function sendEmail(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();
        if(recaptchaValue)
        {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({"body":{"fullName":state.fullName,"email":state.email,"message":state.message,"source":"BWS","token":token}}),
            };
    
            fetch(`https://ac6xrieiik.execute-api.us-east-2.amazonaws.com/default/bws-contact-form`, requestOptions)
            .catch(err => console.log(err))
    
            setIsShow(!isShow);
        }
    }

    return(
        
        <div className="contactRoot">
            <Grid item xs={12} className="contactHero">
                <Typography variant="h2" className="subtitle">
                    Contact Us
                </Typography>
            </Grid>
            <Container maxWidth="lg" className="contactContent">
                <Grid container spacing={1}>
                    <Grid container spacing={1} className="notworking">
                        <Grid item md={6} xs={12}>
                            <Paper square className="contactFormPaper">
                                {isShow ?
                                    <div>
                                        <Typography variant="h4" className="subtitle20 xd">
                                            We Are Here To Help!
                                        </Typography>
                                        <Typography variant="h6" className="subtitle5">
                                            Write us any question you have, our team is ready to answer.
                                        </Typography>
                                        <form 
                                            onSubmit={sendEmail}
                                            className="contactForm"
                                        >
                                            <TextField
                                                required
                                                fullWidth
                                                label="Name"
                                                variant="outlined"
                                                name="fullName"
                                                value={state.fullName}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                fullWidth
                                                label="Email"
                                                variant="outlined"
                                                type="email"
                                                name="email"
                                                value={state.email}
                                                onChange={handleChange}
                                            />
                                            <TextField
                                                required
                                                fullWidth
                                                multiline
                                                rows="4"
                                                label="Message"
                                                variant="outlined"
                                                name="message"
                                                value={state.message}
                                                onChange={handleChange}
                                            />
                                            <ReCAPTCHA 
                                                sitekey='6LcMs7gdAAAAAMcm4rAdZG5rBVb8CiqOLE3qqEXE'
                                                onChange={captchaChange}
                                                ref={recaptchaRef}
                                                className="captcha"
                                            />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="contactFormSubmit"
                                            >
                                                Send Email
                                            </Button>
                                        </form>
                                    </div>
                                :
                                    <div className="center">
                                        <Typography 
                                            variant="h4"
                                            className="subtitle20 xd vertical"
                                        >
                                            Your message has been sent!
                                        </Typography>
                                        <CheckCircleIcon className="checkmark" />
                                    </div>
                                }
                                
                            </Paper>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Paper square className="creditapp">
                                <Typography variant="h4" className="subtitle20 xd">
                                    Apply for Credit
                                </Typography>
                                <div className="creditImgWrapper">
                                    <Link to={creditApplication} target="_blank">
                                        <img src={credit} alt="Credit SVG" className="creditImg" />
                                    </Link>
                                </div>
                                <div className='terms'>
                                    <Link to={termsAndConditions} target="_blank" className='termsLink'>
                                        <Typography variant='h6'>
                                        Terms & Conditions
                                        </Typography>
                                    </Link>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mapWrapper">
                        <iframe 
                            src="https://www.google.com/maps/d/embed?mid=1qfViXFPxqCM5GsphCG_Y0meiRTM8sZek" 
                            width="100%" 
                            height="100%"
                            frameBorder="0" style={{border: 0}}
                            className="map"
                            title="Barnes Welding Supply Locations"
                        >
                        </iframe>
                    </Grid>
                </Grid>
            </Container>
        </div>
    ); 
}