import React from 'react';
import { Typography, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import NewTimelineData from './NewTimelineData';
import './NewTimeline.scss';

export default function NewTimeline() {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const timelineProps = {
        position: isDesktopScreen ? 'alternate' : 'right'
    };

    return (
        <Timeline {...timelineProps} className="timeline-root">
            {NewTimelineData.map((data, i) => 
                <TimelineItem key={i} className='timeline-items'>
                    <TimelineSeparator className='separator'>
                        <TimelineConnector />
                        <TimelineDot className='icon-wrapper'>
                            <SvgIcon component={data.icon} className='icon' />
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className='content'>
                        <Typography variant='h6' className='title'><b>{data.date}</b></Typography>
                        <Typography>{data.content}</Typography>
                    </TimelineContent>
                </TimelineItem>
            )}
        </Timeline>
    )
}