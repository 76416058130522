import StoreIcon from '@mui/icons-material/Store';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';

const TimelineData = [
    { icon: BusinessIcon, date: "2024", content: "Celebrating 75 years in business! Opened West Sacramento store, enlarged our Sacramento store property, and created a new company organizational structure to handle the increased size of the business." },
    { icon: StoreIcon, date: "2023", content: "Began to transport bulk Oxygen." },
    { icon: StoreIcon, date: "2022", content: "Began to transport bulk Argon." },
    { icon: StoreIcon, date: "2021", content: "Began to produce Dry Ice. Moved the main Fresno location to Jensen Ave. Installed a Cryovation automated industrial fill plant." },
    { icon: StoreIcon, date: "2020", content: "Purchased a Medical Delivery Business and began to deliver Liquid Medical Nitrogen, High Pressure Oxygen and Nitrous Oxide to medical facilities." },
    { icon: PeopleIcon, date: "2019", content: "David Barnes retired after 40 years serving as Vice President at Fresno Oxygen. Mikes son Mikey Barnes Accepted the position of Corporation Vice President. The newest Barnes Welding Supply store location opened in Turlock. Began Transporting Bulk Carbon Dioxide" },
    { icon: StoreIcon, date: "2018", content: "Created the TapsCO2 Division." },
    { icon: StoreIcon, date: "2015", content: "The Madera Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "2014", content: "The Salinas Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "2013", content: "The Citrus Heights Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "2004", content: "The Stockton Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "2000", content: "The Sacramento Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "1999", content: "The Modesto Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "1998", content: "The Bakersfield Barnes Welding Supply store location opened up." },
    { icon: PeopleIcon, date: "1995", content: "Red Barnes accepted the position of Chairman of the Board of Directors and continues to remain active in the business. Mike Barnes accepted the position of Corporation President. David Barnes serves as corporation Vice President. Steven Barnes serves as corporation Secretary Treasurer."},
    { icon: StoreIcon, date: "1981", content: "The Clovis and Porterville Barnes Welding Supply store locations opened up." },
    { icon: StoreIcon, date: "1977", content: "The Visalia Barnes Welding Supply store location opened up." },
    { icon: StoreIcon, date: "1974", content: "The Fresno Barnes Welding Supply store location moved locations." },
    { icon: PeopleIcon, date: "1969", content: "Pop Barnes retires and Red becomes President of the company. Red's three sons Mike, Steven, and David all entered the business after graduating from High School."},
    { icon: PersonIcon, date: "1959", content: "Pop's son Red joined the family business as the 5th employee after completing 4 years active duty in the U.S. Air Force."},
    { icon: BusinessIcon, date: "1949", content: "L. J. Pop Barnes established Fresno Oxygen during the winter of 1949 in an unheated corner of the Owl Transfer Moving & Storage Building in Fresno, California."},
];

export default TimelineData;