import React from 'react';
import './SDSManuals.scss';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import manual from '../../imgs/manual.svg';
import sds from '../../imgs/sdswhite.svg';
import G001 from '../../pdfs/sds/G-001 - Oxygen SDS.pdf';
import G002 from '../../pdfs/sds/G-002 - Acetylene SDS.pdf';
import G003 from '../../pdfs/sds/G-003 - Nitrous Oxide SDS.pdf';
import G004 from '../../pdfs/sds/G-004 - Hydrogen SDS.pdf';
import G005 from '../../pdfs/sds/G-005 - Helium SDS.pdf';
import G006 from '../../pdfs/sds/G-006 - Argon SDS.pdf';
import G007 from '../../pdfs/sds/G-007 - Nitrogen SDS.pdf';
import G008 from '../../pdfs/sds/G-008 - Carbon Dioxide SDS.pdf';
import G032 from '../../pdfs/sds/G-032 - Carbon Dioxide (Dry Ice) SDS.pdf';
import G033 from '../../pdfs/sds/G-033 - Ethylene SDS.pdf';
import G056 from '../../pdfs/sds/G-056 - Methane SDS.pdf';
import G057 from '../../pdfs/sds/G-057 - Propane SDS.pdf';
import G058 from '../../pdfs/sds/G-058 - Isobutane SDS.pdf';
import G059 from '../../pdfs/sds/G-059 - Ethane SDS.pdf';
import G098 from '../../pdfs/sds/G-098 - Helium, Liquid SDS.pdf';
import G099 from '../../pdfs/sds/G-099 - Carbon Dioxide, Refrigerated Liquid SDS.pdf';
import G102 from '../../pdfs/sds/G-102 - Oxygen, Refrigerated Liquid SDS.pdf';
import G103 from '../../pdfs/sds/G-103 - Nitrogen, Refrigerated Liquid SDS.pdf';
import G104 from '../../pdfs/sds/G-104 - Argon, Refrigerated Liquid SDS.pdf';
import G106 from '../../pdfs/sds/G-106 - C-Type Mixes SDS.pdf';
import G107 from '../../pdfs/sds/G-107 - A-Type Mixes SDS.pdf';
import G108 from '../../pdfs/sds/G-108 - E-Type Mixes SDS.pdf';
import G109 from '../../pdfs/sds/G-109 - B-Type Mixes SDS.pdf';
import G112 from '../../pdfs/sds/G-112 - PCS Mix Hydrogen Sulfide in Nitrogen SDS.pdf';
import G133 from '../../pdfs/sds/G-133 - CO2 in AIR (1-50) SDS.pdf';
import G135 from '../../pdfs/sds/G-135 - G-Type Mixes SDS.pdf';
import G136 from '../../pdfs/sds/G-136 - Racing Grade Nitrous Oxide SDS.pdf';
import G137 from '../../pdfs/sds/G-137 - J-Type Mixes SDS.pdf';
import G150 from '../../pdfs/sds/G-150 - Methane-Balance Argon 2.2 SDS.pdf';
import G151 from '../../pdfs/sds/G-151 - Methane-Balance Argon 2.1 SDS.pdf';
import G172 from '../../pdfs/sds/G-172 - Propane - Nitrogen 2.1 SDS.pdf';
import G173 from '../../pdfs/sds/G-173 - Q-Type Mixes SDS.pdf';
import G176 from '../../pdfs/sds/G-176 - NI-CD-OX SDS.pdf';
import G178 from '../../pdfs/sds/G-178 - NI-NO-SD SDS.pdf';
import G179 from '../../pdfs/sds/G-179 - NI-IB SDS.pdf';
import G180 from '../../pdfs/sds/G-180 - NO-CO2 in NI SDS.pdf';
import G181 from '../../pdfs/sds/G-181 - Propane - Air SDS.pdf';
import G182 from '../../pdfs/sds/G-182 - NI-NO SDS.pdf';
import G183 from '../../pdfs/sds/G-183 - NI-CD-CO SDS.pdf';
import G184 from '../../pdfs/sds/G-184 - NI-CO SDS.pdf';
import G185 from '../../pdfs/sds/G-185 - NI-SD SDS.pdf';
import G186 from '../../pdfs/sds/G-186 - Ammonia in Nitrogen SDS.pdf';
import G187 from '../../pdfs/sds/G-187 - Methane in Air SDS.pdf';
import G188 from '../../pdfs/sds/G-188 - CO-NO-SD in NI SDS.pdf';
import G189 from '../../pdfs/sds/G-189 - Propylene SDS.pdf';
import G190 from '../../pdfs/sds/G-190 - CO-NO in NI SDS.pdf';
import G191 from '../../pdfs/sds/G-191 - Sulfur Hexafluoride SDS.pdf';
import G192 from '../../pdfs/sds/G-192 - Air SDS.pdf';
import G193 from '../../pdfs/sds/G-193 - F-Type Mixes SDS.pdf';
import G194 from '../../pdfs/sds/G-194 - Carbon Monoxide SDS.pdf';
import G195 from '../../pdfs/sds/G-195 - CO2-OX USP Mix SDS.pdf';
import G196 from '../../pdfs/sds/G-196 - HY in NI (0 - 5.7) SDS.pdf';
import G197 from '../../pdfs/sds/G-197 - HY in NI (5.7 - 99) SDS.pdf';
import G198 from '../../pdfs/sds/G-198 - CO2-NI-HE SDS.pdf';
import G199 from '../../pdfs/sds/G-199 - NO 2.31 SDS.pdf';
import G200 from '../../pdfs/sds/G-200 - NO2 Bal Inert Gas Mix SDS.pdf';
import G201 from '../../pdfs/sds/G-201 - ET-ME-NB-NP-NH-PR in NI SDS.pdf';
import G203 from '../../pdfs/sds/G-203 - Lung Diffusion Mix (CO-NE-OX-NI) SDS.pdf';
import G204 from '../../pdfs/sds/G-204 - CD-OX (0 - 23) SDS.pdf';
import G205 from '../../pdfs/sds/G-205 - Krypton SDS.pdf';
import G206 from '../../pdfs/sds/G-206 - Sulfur Dioxide SDS.pdf';
import G207 from '../../pdfs/sds/G-207 - H-Type Mixes SDS.pdf';
import G208 from '../../pdfs/sds/G-208 - Lung Diffusion Mix (CO-ME-Air) SDS.pdf';
import G209 from '../../pdfs/sds/G-209 - CO2-OX in NI SDS.pdf';
import G210 from '../../pdfs/sds/G-210 - H2S-NI SDS.pdf';
import G211 from '../../pdfs/sds/G-211 - SO2 in Air SDS.pdf';
import G212 from '../../pdfs/sds/G-212 - H2S-Pentene-CO In Air SDS.pdf';
import G213 from '../../pdfs/sds/G-213 - HY in AR Non-Flammable SDS.pdf';
import G214 from '../../pdfs/sds/G-214 - H2 in AR SDS.pdf';
import G215 from '../../pdfs/sds/G-215 - CO2-NI-HY SDS.pdf';
import G216 from '../../pdfs/sds/G-216 - CO-CO2-HE-NI Mix Laser 2 SDS.pdf';
import G217 from '../../pdfs/sds/G-217 - Bar Gas Calibration Mixtures SDS.pdf';
import G218 from '../../pdfs/sds/G-218 - Multigas HC SDS.pdf';
import G219 from '../../pdfs/sds/G-219 - Ammonia, Anhydrous SDS.pdf';
import G220 from '../../pdfs/sds/G-220 - D-Type Mixes SDS.pdf';
import G221 from '../../pdfs/sds/G-221 - Quad Mix (CH4-CO-HE-AIR) SDS.pdf';
import G222 from '../../pdfs/sds/G-222 - W-Type Mixes SDS.pdf';
import G224 from '../../pdfs/sds/G-224 - NI in Methane SDS.pdf';
import G226 from '../../pdfs/sds/G-226 - N2-CH4-H2S SDS.pdf';
import G227 from '../../pdfs/sds/G-227 - HY-AR-N2 SDS.pdf';
import G228 from '../../pdfs/sds/G-228 - H2S-CH4-O2 SDS.pdf';
import G229 from '../../pdfs/sds/G-229 - CO-HY in NI SDS.pdf';
import G230 from '../../pdfs/sds/G-230 - Butane in Air SDS.pdf';
import G231 from '../../pdfs/sds/G-231 - 1-Butene SDS.pdf';
import G232 from '../../pdfs/sds/G-232 - Hydrogen Chloride SDS.pdf';
import G233 from '../../pdfs/sds/G-233 - 1-Pentene SDS.pdf';
import G234 from '../../pdfs/sds/G-234 - Mulitigas Hydrocarbon Blend SDS.pdf';
import G235 from '../../pdfs/sds/G-235 - H2S-CD in Methane SDS.pdf';
import G236 from '../../pdfs/sds/G-236 - H2S in Methane SDS.pdf';
import G237 from '../../pdfs/sds/G-237 - I-Type Mixes SDS.pdf';
import G238 from '../../pdfs/sds/G-238 - Propylene in NI 2.1 SDS.pdf';
import G239 from '../../pdfs/sds/G-239 - Lung Difussion Test Mixture SDS.pdf';
import G240 from '../../pdfs/sds/G-240 - Silane SDS.pdf';
import G241 from '../../pdfs/sds/G-241 - Butane SDS.pdf';
import G242 from '../../pdfs/sds/G-242 - Ethylene in Air SDS.pdf';
import G277 from '../../pdfs/sds/G-277 - Y-Type Mixes SDS.pdf';
import G278 from '../../pdfs/sds/G-278 - PXG-Type Mixes SDS.pdf';
import G279 from '../../pdfs/sds/G-279 - Isopropyl Alcohol SDS.pdf';
import G280 from '../../pdfs/sds/G-280 - Ethanol SDS.pdf';
import G282 from '../../pdfs/sds/G-282 - Multigas Mixture bal Mehtane SDS.pdf';
import G283 from '../../pdfs/sds/G-283 - CH4-CO2 Mixes 2.1 SDS.pdf';
import G284 from '../../pdfs/sds/G-284 - CO2-H2 Bal NI SDS.pdf'
import G060 from '../../pdfs/sds/G-060 - N-Pentane SDS.pdf';
import G113 from '../../pdfs/sds/G-113 - Acetone SDS.pdf';
import G114 from '../../pdfs/sds/G-114 - Calcium Carbide SDS.pdf';
import G115 from '../../pdfs/sds/G-115 - Calcium Chloride SDS.pdf';
import G285 from '../../pdfs/sds/G-285 - Propylene-Bal NI 2.1 SDS.pdf';
import G286 from '../../pdfs/sds/G-286 - P-Type Mixes SDS.pdf';
import G287 from '../../pdfs/sds/G-287 - OX-NI SDS.pdf';
import G288 from '../../pdfs/sds/G-288 - EPA Mix CO2-CO-NO-NI SDS.pdf';
import G289 from '../../pdfs/sds/G-289 - Isobutane in Air SDS.pdf';
import G290 from '../../pdfs/sds/G-290 - Hexane in NI SDS.pdf';
import G291 from '../../pdfs/sds/G-291 - CO2-HY-in NI SDS.pdf';
import G292 from '../../pdfs/sds/G-292 - Propane in NI SDS.pdf';
import G293 from '../../pdfs/sds/G-293 - CH4 in HE 2.2 SDS.pdf';
import G294 from '../../pdfs/sds/G-294 - OX in HE SDS.pdf';
import G295 from '../../pdfs/sds/G-295 - CH4-CO Mix SDS.pdf';
import G296 from '../../pdfs/sds/G-296 - Gas Standard w_1 ppm Sulfur Comp. in NI SDS.pdf';
import G297 from '../../pdfs/sds/G-297 - Pentane SIM Mix with 20 ppm H2S SDS.pdf';
import G298 from '../../pdfs/sds/G-298 - GC Calibration Gas SDS.pdf';
import G299 from '../../pdfs/sds/G-299 - GC Calibration Gas SDS.pdf';
import G300 from '../../pdfs/sds/G-300 - CO in Air SDS.pdf';
import G301 from '../../pdfs/sds/G-301 - NO2 in Air SDS.pdf';
import G302 from '../../pdfs/sds/G-302 - CO2-OX in Methane SDS.pdf';
import G303 from '../../pdfs/sds/G-303 - CO-OX in Nitrogen SDS.pdf';
import G319 from '../../pdfs/sds/G-319 - ET-ME-NB-NP-NH-PR in NI Non-Flammable SDS.pdf';

export default function SDSManuals() {
    return(
        <div className="sdsRoot">
            <Grid item xs={12} className="sdsHero">
                <Typography variant="h2" className="sub-heading1">
                    Safety Data Sheets & Manuals
                </Typography>
            </Grid>
            <Container maxWidth="lg" className="sdsmanual">
                <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                        <Paper square className="sdsContent">
                            <div className="sdsImgWrapper">
                                <img src={sds} alt="sds" className="sdsImg" />
                            </div>
                            <Typography variant="h5" className="subtitle2" component="h3">
                                SDS Information
                            </Typography>
                            <Typography>
                                Below are links to manufacturer's sites providing U.S. Safety Data Sheets.
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        <a href="https://sds.airproducts.com/" target="_blank" rel="noopener noreferrer">Air Products</a>: A site for SDSs covering industrial gases & chemicals
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.chemadvisor.com/matheson/" target="_blank" rel="noopener noreferrer">Matheson Tri Gas</a>: A site for SDSs covering industrial gases & chemicals
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.chemtaneenergy.com/sds" target="_blank" rel="noopener noreferrer">Chemtane2 Fuel Gas</a>: The SDS for the proprietary fuel gas, Chemtane2
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.hobartbrothers.com/aboutus/msds/" target="_blank" rel="noopener noreferrer">Hobart</a>: Here you'll find SDS product information about McKay filler metals, Tri-Mark electrodes, and Hobart product lines
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.lincolnelectric.com/en-us/support/msds/Pages/sds-search.aspx" target="_blank" rel="noopener noreferrer">Lincoln Electric Company</a>: Here you'll find SDS product information about Lincoln Electric Welding Consumables and Harris Filler Metals.
                                    </Typography>
                                </li>
                            </ul>
                        </Paper>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Paper square className="manualContent">
                            <div className="manualImgWrapper">
                                <img src={manual} alt="manual" className="manualImg" />
                            </div>
                            <Typography variant="h5" className="subtitle2" component="h3">
                                Owners Manuals and Technical Information
                            </Typography>
                            <Typography>
                                Need a copy of your equipment's owners manual? Check the links below!
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        <a href="https://www.millerwelds.com/support/manuals-and-parts" target="_blank" rel="noopener noreferrer">Miller Electric Owners Manuals</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.lincolnelectric.com/en-us/support/Pages/operator-manuals.aspx" target="_blank" rel="noopener noreferrer">Lincoln Electric Owners Manuals</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.hypertherm.com/en-US/customer-support/documents-library/?category=60&subcategory=510&region=NART" target="_blank" rel="noopener noreferrer">Hypertherm Owners Manuals</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="http://manuals.esab.com/" target="_blank" rel="noopener noreferrer">ESAB Owners Manuals</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.makita.co.nz/library/manuals/all/1/" target="_blank" rel="noopener noreferrer">Makita Owners Manuals</a>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <a href="https://www.dewalt.com/support" target="_blank" rel="noopener noreferrer">DeWALT Owners Manuals</a>
                                    </Typography>
                                </li>
                            </ul>
                        </Paper>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} className="mTable">
                        <MaterialTable
                            columns={[
                                { title: 'SDS Doc #', field: 'number' },
                                { title: 'Product Name', field: 'name' },
                            ]}
                            data={[
                                { number: 'G-001', name: 'Oxygen', link: G001 },
                                { number: 'G-002', name: 'Acetylene', link: G002 },
                                { number: 'G-003', name: 'Nitrous Oxide', link: G003 },
                                { number: 'G-004', name: 'Hydrogen', link: G004 },
                                { number: 'G-005', name: 'Helium', link: G005 },
                                { number: 'G-006', name: 'Argon', link: G006 },
                                { number: 'G-007', name: 'Nitrogen', link: G007 },
                                { number: 'G-008', name: 'Carbon Dioxide', link: G008 },
                                { number: 'G-032', name: 'Carbon Dioxide, Solid - Dry Ice', link: G032 },
                                { number: 'G-033', name: 'Ethylene', link: G033 },
                                { number: 'G-056', name: 'Methane', link: G056 },
                                { number: 'G-057', name: 'Propane', link: G057 },
                                { number: 'G-058', name: 'Isobutane', link: G058 },
                                { number: 'G-059', name: 'Ethane', link: G059 },
                                { number: 'G-060', name: 'N-Pentane', link: G060 },
                                { number: 'G-098', name: 'Helium, Refrigerated Liquid', link: G098 },
                                { number: 'G-099', name: 'Carbon Dioxide, Refrigerated Liquid', link: G099 },
                                { number: 'G-102', name: 'Oxygen, Refrigerated Liquid', link: G102 },
                                { number: 'G-103', name: 'Nitrogen, Refrigerated Liquid', link: G103 },
                                { number: 'G-104', name: 'Argon, Refrigerated Liquid', link: G104 },
                                { number: 'G-106', name: 'C-Type Mixes', link: G106 },
                                { number: 'G-107', name: 'A-Type Mixes', link: G107 },
                                { number: 'G-108', name: 'E-Type Mixes', link: G108 },
                                { number: 'G-109', name: 'B-Type Mixes', link: G109 },
                                { number: 'G-112', name: 'PCS Mix - Hydrogen Sulfide in Nitrogen', link: G112 },
                                { number: 'G-113', name: 'Acetone', link: G113 },
                                { number: 'G-114', name: 'Calcium Carbide', link: G114 },
                                { number: 'G-115', name: 'Calcium Chloride', link: G115 },
                                { number: 'G-133', name: 'CO2 in Air (1-50%)', link: G133 },
                                { number: 'G-135', name: 'G-Type Mixes', link: G135 },
                                { number: 'G-136', name: 'Racing Grade Nitrous Oxide', link: G136 },
                                { number: 'G-137', name: 'J-Type Mixes', link: G137 },
                                { number: 'G-150', name: 'Methane-Balance Argon 2.2', link: G150 },
                                { number: 'G-151', name: 'Methane-Balance Argon 2.1', link: G151 },
                                { number: 'G-172', name: 'Propane / Nitrogen', link: G172 },
                                { number: 'G-173', name: 'Q-Type Mixes', link: G173 },
                                { number: 'G-176', name: 'NI-CD-OX', link: G176 },
                                { number: 'G-178', name: 'NI-NO-SD', link: G178 },
                                { number: 'G-179', name: 'NI-IB', link: G179 },
                                { number: 'G-180', name: 'NO-CO2 in NI', link: G180 },
                                { number: 'G-181', name: 'Propane / Air', link: G181 },
                                { number: 'G-182', name: 'NI-NO', link: G182 },
                                { number: 'G-183', name: 'NI-CD-CM', link: G183 },
                                { number: 'G-184', name: 'NI-CM', link: G184 },
                                { number: 'G-185', name: 'NI-SD', link: G185 },
                                { number: 'G-186', name: 'Ammonia in Nitrogen', link: G186 },
                                { number: 'G-187', name: 'Methane in Air', link: G187 },
                                { number: 'G-188', name: 'CO-NO-SD in NI', link: G188 },
                                { number: 'G-189', name: 'Propylene', link: G189 },
                                { number: 'G-190', name: 'CO-NO in NI', link: G190 },
                                { number: 'G-191', name: 'Sulfur Hexafluoride', link: G191 },
                                { number: 'G-192', name: 'Air', link: G192 },
                                { number: 'G-193', name: 'F-Type Mixes', link: G193 },
                                { number: 'G-194', name: 'Carbon Monoxide', link: G194 },
                                { number: 'G-195', name: 'CO2-OX USP Mix', link: G195 },
                                { number: 'G-196', name: 'HY in NI (< 5.7%)', link: G196 },
                                { number: 'G-197', name: 'HY in NI (5.7% - 99%)', link: G197 },
                                { number: 'G-198', name: 'CO2-NI-HE', link: G198 },
                                { number: 'G-199', name: 'NO 2.31', link: G199 },
                                { number: 'G-200', name: 'NO2 Bal Inert Gas Mix', link: G200 },
                                { number: 'G-201', name: 'ET-ME-NB-NP-NH-PR in NI', link: G201 },
                                { number: 'G-203', name: 'Lung Diffusion Mix (CO-NE-OX-NI)', link: G203 },
                                { number: 'G-204', name: 'CD-OX (< 23%)', link: G204 },
                                { number: 'G-205', name: 'Krypton', link: G205 },
                                { number: 'G-206', name: 'Sulfur Dioxide', link: G206 },
                                { number: 'G-207', name: 'H-Type Mixes', link: G207 },
                                { number: 'G-208', name: 'Lung Diffusion Mix (CO-ME-Air)', link: G208 },
                                { number: 'G-209', name: 'Carbon Dioxide and Oxygen in Nitrogen', link: G209 },
                                { number: 'G-210', name: 'H2S-NI', link: G210 },
                                { number: 'G-211', name: 'SO2 in Air', link: G211 },
                                { number: 'G-212', name: 'SO2-Pentene-CO in Air', link: G212 },
                                { number: 'G-213', name: 'HY in AR Non-Flammable', link: G213 },
                                { number: 'G-214', name: 'H2 in AR', link: G214 },
                                { number: 'G-215', name: 'CO2-NI-HY', link: G215 },
                                { number: 'G-216', name: 'CO/CO2/HE/NI Mix Laser 2', link: G216 },
                                { number: 'G-217', name: 'Bar Gas Calibration Mixtures', link: G217 },
                                { number: 'G-218', name: 'Multigas HC', link: G218 },
                                { number: 'G-219', name: 'Ammonia, Anhydrous', link: G219 },
                                { number: 'G-220', name: 'D-Type Mixes', link: G220 },
                                { number: 'G-221', name: 'Quad Mix (CH4-CO-HE-AIR)', link: G221 },
                                { number: 'G-222', name: 'W-Type Mixes', link: G222 },
                                { number: 'G-224', name: 'NI in Methane', link: G224 },
                                { number: 'G-226', name: 'N2-CH4-H2S - CS Mixture', link: G226 },
                                { number: 'G-227', name: 'HY-AR-N2', link: G227 },
                                { number: 'G-228', name: 'H2S-CH4-O2', link: G228 },
                                { number: 'G-229', name: 'CO-HY in NI', link: G229 },
                                { number: 'G-230', name: 'Butane in Air', link: G230 },
                                { number: 'G-231', name: '1-Butene', link: G231 },
                                { number: 'G-232', name: 'Hydrogen Chloride', link: G232 },
                                { number: 'G-233', name: '1-Pentene', link: G233 },
                                { number: 'G-234', name: 'Multigas Hydrocarbon Blend', link: G234 },
                                { number: 'G-235', name: 'H2S-CD in Methane', link: G235 },
                                { number: 'G-236', name: 'H2S in Methane', link: G236 },
                                { number: 'G-237', name: 'I-Type Mixes', link: G237 },
                                { number: 'G-238', name: 'Propylene in NI', link: G238 },
                                { number: 'G-239', name: 'Lung Diffusion Test Mixture', link: G239 },
                                { number: 'G-240', name: 'Silane', link: G240 },
                                { number: 'G-241', name: 'Butane', link: G241 },
                                { number: 'G-242', name: 'Ethylene in Air', link: G242 },
                                { number: 'G-277', name: 'Y-Type Mixes', link: G277 },
                                { number: 'G-278', name: 'PXG-Type Mixes', link: G278 },
                                { number: 'G-279', name: 'Isopropyl Alcohol', link: G279 },
                                { number: 'G-280', name: 'Ethanol', link: G280 },
                                { number: 'G-282', name: 'Multigas Mixture Bal. Methane', link: G282 },
                                { number: 'G-283', name: 'CH4-CO2 Mixes 2.1', link: G283 },
                                { number: 'G-284', name: 'CO2-H2 Bal NI', link: G284 },
                                { number: 'G-285', name: 'Propylene-Bal NI 2.1', link: G285 },
                                { number: 'G-286', name: 'P-Type Mixes', link: G286 },
                                { number: 'G-287', name: 'OX-NI', link: G287 },
                                { number: 'G-288', name: 'EPA Mix CO2-CO-NO-NI', link: G288 },
                                { number: 'G-289', name: 'Isobutane in Air', link: G289 },
                                { number: 'G-290', name: 'Hexane in NI', link: G290 },
                                { number: 'G-291', name: 'CO2-HY-in NI', link: G291 },
                                { number: 'G-292', name: 'Propane in NI', link: G292 },
                                { number: 'G-293', name: 'CH4 in HE 2.2', link: G293 },
                                { number: 'G-294', name: 'OX in HE', link: G294 },
                                { number: 'G-295', name: 'CH4-CO Mix', link: G295 },
                                { number: 'G-296', name: 'Gas Standard w/ 1 ppm Sulfur Comp. in NI', link: G296 },
                                { number: 'G-297', name: 'Pentane SIM Mix w/ 20 ppm H2S', link: G297 },
                                { number: 'G-298', name: 'GC Calibration Gas', link: G298 },
                                { number: 'G-299', name: 'GC Calibration Gas', link: G299 },
                                { number: 'G-300', name: 'CO in Air', link: G300 },
                                { number: 'G-301', name: 'NO2 in Air', link: G301 },
                                { number: 'G-302', name: 'CO2-OX in Methane', link: G302 },
                                { number: 'G-303', name: 'CO-OX in Nitrogen', link: G303 },
                                { number: 'G-319', name: 'ET-ME-NB-NP-NH-PR in NI Non-Flammable', link: G319 },
                            ]}
                            actions={[
                                {
                                    icon: 'save',
                                    tooltip: 'Download',
                                    onClick: (event, rowData) => window.open(rowData.link, + "_blank"),
                                }
                            ]}
                            options={{
                                headerStyle: {
                                    backgroundColor: '#1d1d1d',
                                },
                                color: '#F4DD09',
                                searchFieldStyle: {
                                    color: '#F4DD09',
                                },
                                rowStyle: {
                                    color: 'white',
                                    padding: '25px',
                                },
                                cellStyle: {
                                    color: 'white',
                                }
                            }}
                            title="Barnes Welding Supply Safety Data Sheets"
                        />
                    </Grid>
            </Container>
        </div>
    );
}